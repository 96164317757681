import { WALLET_ACTIONS } from './constants';

const initialState = {
  isFetching: false,
  wallet: {},
  transactions: [],
  page: 1,
  total: 1,
};

export const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case WALLET_ACTIONS.payout.request:
      return {
        ...state,
        isFetching: true,
      };
    case WALLET_ACTIONS.payout.success:
      return {
        ...state,
        isFetching: false,
      };
    case WALLET_ACTIONS.payout.error:
      return {
        ...state,
        isFetching: false,
      };
    case WALLET_ACTIONS.wallet.request:
      return {
        ...state,
        isFetching: true,
      };
    case WALLET_ACTIONS.wallet.success:
      return {
        ...state,
        isFetching: false,
        wallet: action.payload,
      };
    case WALLET_ACTIONS.wallet.error:
      return {
        ...state,
        isFetching: false,
      };
    case WALLET_ACTIONS.transactions.request:
      return {
        ...state,
        isFetching: true,
      };
    case WALLET_ACTIONS.transactions.success:
      return {
        ...state,
        isFetching: false,
        transactions: action.payload,
      };
    case WALLET_ACTIONS.transactions.error:
      return {
        ...state,
        isFetching: false,
      };
    case WALLET_ACTIONS.resetState:
      return initialState;
    default:
      return state;
  }
};
