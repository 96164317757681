import React, { Component } from 'react';
import {
  setAuthorizedUserInfoAction,
  setUserAsNotAuthorizedAction,
  updateUserRequestStatus,
  fetchUserMatchesAction,
} from '../store/user/actions';
import { fetchConfigAction } from '../store/config/actions';
import { fetchWalletAction } from '../store/wallet/actions';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import PagePreloader from '../components/presentation/PagePreloader/PagePreloader';
import AppRouting from './AppRouting/AppRouting';
import { parseJwt, propValueOr } from '../helpers/common';
import { api, pusher } from '../index';
import AppScrollToTop from './AppScrollToTop/AppScrollToTop';
import ReactGA from 'react-ga';
import * as gtag from '../helpers/gtag';
import moment from 'moment';
import AccessCodeDialog from './AccessCodeDialog/AccessCodeDialog';
import LogRocket from 'logrocket';
import FastClick from 'fastclick';
import useUser from 'data/user';

export const withUser = (Component) => {
  return (props) => {
    const { loggedOut, loading } = useUser();

    return <Component loading={loading} loggedOut={loggedOut} {...props} />;
  };
};

class App extends Component {
  constructor(props) {
    super();

    this.state = {
      accessCodeValid: false,
    };
  }

  componentDidMount() {
    const {
      setAuthorizedUserInfoAction,
      setUserAsNotAuthorizedAction,
      history,
    } = this.props;

    const token = window.localStorage.getItem('jwt');

    // Google analytics listen for page change
    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });

    if (token) {
      const user = parseJwt(token);
      if (user?.organizationId) {
        api.updateTokens();
        // Get user org from database
        api.config.get().then((res) => {
          const { slug } = res.data;
          if (slug) {
            localStorage.setItem('organization', slug);
          }
        });
      }
      if (user.id) {
        gtag.login(user.id);
        if (process.env.REACT_APP_LOG_ROCKET) {
          LogRocket.identify(user.id, {
            username: user.username,
          });
        }
        pusher.subscribe(`user-${user.id}`);
      }
      if (moment().isBefore(moment(new Date(user.exp * 1000)))) {
        setAuthorizedUserInfoAction(user);
      }
    } else {
      window.addEventListener('storage', () => {
        // When local storage changes, recheck if user is logged in
        const token = window.localStorage.getItem('jwt');
        if (token) {
          const user = parseJwt(token);
          setAuthorizedUserInfoAction(user);
          pusher.subscribe(`user-${user.id}`);
        }
      });
      setUserAsNotAuthorizedAction();
    }

    window.addEventListener('load', () => {
      FastClick.attach(document.body);
    });
  }

  componentDidUpdate(prevProps) {
    const { location, user } = this.props;
    const requestStatus = user.requestStatus;

    // reset request status when user goes to another page
    if (!!requestStatus && location !== prevProps.location) {
      this.props.updateUserRequestStatus(null);
      window.scrollTo(0, 1);
    }
  }

  render() {
    const { accessCodeValid } = this.state;
    const { user, organizationId, accessCode, loading } = this.props;

    if (loading) {
      return <PagePreloader />;
    }

    if (
      organizationId &&
      accessCode &&
      !accessCodeValid &&
      !propValueOr(user, 'logged')
    ) {
      return (
        <AccessCodeDialog
          onSuccess={() => this.setState({ accessCodeValid: true })}
        />
      );
    }

    return (
      <AppScrollToTop>
        <AppRouting user={user} />
      </AppScrollToTop>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  organizationId: state.configState.id,
  accessCode: state.configState.accessCode,
});
const mapDispatchToProps = {
  setAuthorizedUserInfoAction,
  setUserAsNotAuthorizedAction,
  updateUserRequestStatus,
  fetchConfigAction,
  fetchUserMatchesAction,
  fetchWalletAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withUser(App)));
