import { ARENAS_ACTIONS } from './constants';

const initialState = {
  matches: [],
  tournaments: [],
  scheduled: [],
  custom: [],
  lobbies: [],
  matchTemplate: null,
  isFetching: false,
  isFetchingLobbies: false,
  page: 1,
  total: 1,
  error: null,
};

export const arenasReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARENAS_ACTIONS.tournaments.request:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ARENAS_ACTIONS.tournaments.success:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: false,
      };
    case ARENAS_ACTIONS.tournaments.error:
      return {
        ...state,
        matches: [],
        tournaments: [],
        scheduled: [],
        custom: [],
        isFetching: false,
        error: action.payload,
      };
    case ARENAS_ACTIONS.lobbies.request:
      return {
        ...state,
        isFetchingLobbies: true,
        error: null,
      };
    case ARENAS_ACTIONS.lobbies.success:
      return {
        ...state,
        lobbies: action.payload.docs,
        page: action.payload.page,
        total: action.payload.total,
        isFetchingLobbies: false,
        error: false,
      };
    case ARENAS_ACTIONS.lobbies.error:
      return {
        ...state,
        lobbies: [],
        page: 1,
        total: 1,
        isFetchingLobbies: false,
        error: action.payload,
      };
    case ARENAS_ACTIONS.matchTemplate.request:
      return {
        ...state,
        isFetching: true,
      };
    case ARENAS_ACTIONS.matchTemplate.success:
      return {
        ...state,
        isFetching: false,
        matchTemplate: action.payload,
      };
    case ARENAS_ACTIONS.matchTemplate.error:
      return {
        ...state,
        matchTemplate: null,
        isFetching: false,
        error: action.payload,
      };
    case ARENAS_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};
