import useSWR from 'swr';
import { api } from 'index';

export default function useUser() {
  const { data, mutate, error } = useSWR(
    'user',
    () => api.user.fetch().then((res) => res.data),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 2 times on 401. User is logged out.
        if (retryCount >= 2 && error.response?.status === 401) {
          return null;
        }
        // Retry once to update tokens if JWT exists.
        if (error.response?.status === 401) {
          const token = window.localStorage.getItem('jwt');
          if (token) {
            return api.updateTokens();
          }
          return null;
        }
      },
    }
  );

  const updateUser = (data) => {
    // Update the user with the new data
    return api.user.update(data).then((resp) => {
      const token = resp?.data?.access_token;
      if (token) {
        // set new token to local storage
        window.localStorage.setItem('jwt', token);
      }

      // Refetch user data after successful update
      mutate();
    });
  };

  const loading = !data && !error;
  const loggedOut = !data || (error && error.response?.status === 401);

  return {
    loading,
    loggedOut,
    user: data,
    updateUser,
    mutate,
    error,
  };
}
