export const ARENAS_ACTIONS = {
  selectedTemplate: 'ARENAS/SELECTED_TEMPLATE',
  tournaments: {
    request: 'ARENAS/FETCH_TOURNAMENTS_REQUEST',
    success: 'ARENAS/FETCH_TOURNAMENTS_SUCCESS',
    error: 'ARENAS/FETCH_TOURNAMENTS_ERROR',
  },
  matchTemplate: {
    request: 'ARENAS/FETCH_MATCHTEMPLATE_REQUEST',
    success: 'ARENAS/FETCH_MATCHTEMPLATE_SUCCESS',
    error: 'ARENAS/FETCH_MATCHTEMPLATE_ERROR',
  },
  lobbies: {
    request: 'ARENAS/FETCH_LOBBIES_REQUEST',
    success: 'ARENAS/FETCH_LOBBIES_SUCCESS',
    error: 'ARENAS/FETCH_LOBBIES_ERROR',
  },
  reset: 'ARENAS/RESET_STATE',
};
