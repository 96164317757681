export const LEAGUES_ACTIONS = {
  fetch: {
    request: 'LEAGUES/FETCH_REQUEST',
    success: 'LEAGUES/FETCH_SUCCESS',
    error: 'LEAGUES/FETCH_ERROR',
  },
  append: {
    request: 'LEAGUES/APPEND_REQUEST',
    success: 'LEAGUES/APPEND_SUCCESS',
    error: 'LEAGUES/APPEND_ERROR',
  },
  fetchedAll: 'LEAGUES/FETCHED_ALL',
  reset: 'LEAGUES/RESET_STATE',
};
