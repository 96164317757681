import React from 'react';
import Loader from '../../../components/presentation/Loader/Loader';

function PagePreloader(props) {
  return <Loader isBlock={false} />;
}

PagePreloader.propTypes = {};

export default PagePreloader;
