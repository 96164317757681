import { CONSOLES_ACTIONS } from './constants';

const initialState = {
  isFetching: false,
  list: [],
  error: null,
};

export const consolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSOLES_ACTIONS.consoles.request:
      return {
        ...state,
        isFetching: true,
      };
    case CONSOLES_ACTIONS.consoles.success:
      return {
        ...state,
        list: action.payload,
        isFetching: false,
      };
    case CONSOLES_ACTIONS.consoles.error:
      return {
        ...state,
        isFetching: false,
      };
    case CONSOLES_ACTIONS.resetState:
      return initialState;
    default:
      return state;
  }
};
