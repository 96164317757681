export const LEAGUE_ACTIONS = {
  fetch: {
    request: 'LEAGUE/FETCH_REQUEST',
    success: 'LEAGUE/FETCH_SUCCESS',
    error: 'LEAGUE/FETCH_ERROR',
  },
  join: {
    request: 'LEAGUE/JOIN_REQUEST',
    success: 'LEAGUE/JOIN_SUCCESS',
    error: 'LEAGUE/JOIN_ERROR',
  },
  leave: {
    request: 'LEAGUE/LEAVE_REQUEST',
    success: 'LEAGUE/LEAVE_SUCCESS',
    error: 'LEAGUE/LEAVE_ERROR',
  },
  playerCount: {
    update: 'LEAGUE/PLAYERCOUNT_UPDATE',
  },
  reset: 'LEAGUE/RESET_STATE',
};
