export const FONT_FAMILY_DEFAULT = {
  THICK: "'Barlow Condensed', sans-serif", // in psd it is BebasNeueBold
  THIN: "'Pill Gothic', sans-serif", // in psd it is SofiaProLight
  CONDENSED: "'Pill Gothic', sans-serif",
};

export const HEADER_HEIGHT = '60px';

export const MAX_WIDTH = '1200px';

export const FONT_FAMILY = FONT_FAMILY_DEFAULT;

export const FONT_SIZE = {
  H1: 60,
  H2: 34,
  H3: 28,
  H4: 22,
  H5: 18,
  H6: 18,
  PARAGRAPH: 16,
  PARAGRAPH_SMALL: 14,
  CAPTION_1: 28,
  CAPTION_2: 14,
  CAPTION_3: 16,
  SMALL: 12,
  LINK: 16,
  LINK_SMALL: 14,
  BUTTON: 14,
  BUTTON_SMALL: 12,
  LABEL: 14,
  FIELD: 14,
};

export const FONT_WEIGHT = {
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  BOLD: 600,
  EXTRA_BOLD: 700,
};

export const FONT = {
  CAPTION_1: `${FONT_WEIGHT.BOLD} ${FONT_SIZE.CAPTION_1}px / 1.29 ${FONT_FAMILY.THICK}`,
  BUTTON: `${FONT_WEIGHT.EXTRA_BOLD} ${FONT_SIZE.BUTTON}px / 1 ${FONT_FAMILY.THIN}`,
  BUTTON_SMALL: `${FONT_WEIGHT.EXTRA_BOLD} ${FONT_SIZE.BUTTON_SMALL}px / 1.29 ${FONT_FAMILY.THIN}`,
  PARAGRAPH: `${FONT_WEIGHT.LIGHT} 14px ${FONT_FAMILY.THIN}`,
};

const COLOR_DEFAULT = {
  LIGHT_RED: '#DB3356',
  ERROR: '#ec1c29',
  ROUGE: '#a5111b',
  RIVAL_RED: '#FD174F',
  RIVAL_RED_2: '#B90454',
  RIVAL_RED_3: '#EE0D53',
  COKE_RED: '#F40009',
  BANNER_BLUE: '#2283BE',
  LEAGUES_BLUE: '#009FFB',
  TOURNAMENT_PURPLE: '#6E57D0',
  ARENA_GREEN: '#00B47A',

  GREY_BLUE: '#0E1922',
  LIGHT_BLUE: '#6485c1',
  TEXT_LINK: '#005cff',
  AQUA: '#52C3D0',
  PS_BLUE: '#003087',
  TWITCH_PURPLE: '#822FDB',
  NINTENDO_RED: '#e4000f',
  PC_GREY: '#607999',
  LIGHT_GREEN: '#2DC12F',
  GREEN: '#169617',
  XBOX_GREEN: '#0E7A0D',
  YELLOW: '#ffb700',
  YELLOW_2: '#fdc02d', // new color for button
  OBSIDIAN: '#000000',
  BLACK: '#1c1c1e',
  BLACK_2: '#2c2c2e',
  BLACK_3: '#3a3a3c',
  GREY: '#48484a',
  GREY_2: '#cccccc',
  GREY_3: '#eeeeee',
  GREY_4: '#f2f2f2',
  GREY_5: '#979797',
  WHITE: '#ffffff',

  TINT_GREEN: '#00b47a',
  TINT_PURPLE: '#6e57d0',
  TINT_BLUE: '#009FFB',
  TINT_YELLOW: '#F3D011',
  TINT_ORANGE: '#FE5327',

  BG_PRIMARY: '#000000',
  BG_SECONDARY: '#1c1c1e',
  BG_THIRD: '#000000',
  BG_FOURTH: '#1c1c1e',
  BG_ACCENT: '#000000',
  BG_INPUT: '#000000',
  BG_DARKER: '#000000',
  BG_1: '#1c1c1c',
  BG_2: '#1c1c1c',
  BG_HEADER_NOTAUTH: '#1c1c1c',
  BG_FOOTER_NOTAUTH: '#000000',
  BG_HEADER: '#1c1c1c',
  BG_FOOTER: '#000000',
  FG_PRIMARY: '#ffffff',
  FG_SECONDARY: '#ff004f',
  FG_ACCENT: '#053385',
  FG_INPUT: '#bdbdbd',
  TEXT: '#ffffff', //BLACK
  TEXT_2: '#ffffff', //BLACK_2
  TEXT_3: '#ffffff', //BLACK_3
  TEXT_4: '#ffffff', //GREY
  TEXT_5: '#ffffff', //GREY_2
  TEXT_6: '#8E8E93', //GREY_3
  LINK: '#80acff', //light blue
  BORDER: '#2c2c2e',
  BORDER_2: '#2c2c2e',
  BORDER_3: '#2c2c2e',
  BORDER_4: '#2c2c2e',
  BORDER_5: '#2c2c2e',
  BLUE: '#0253e2',

  // button kit appearance
  BUTTON_DISABLED_BG_PRIMARY: '#EEEEEE',
  BUTTON_DISABLED_BG_SECONDARY: 'transparent',
  BUTTON_DISABLED_BORDER_PRIMARY: '#CCCCCC',
  BUTTON_DISABLED_BORDER_SECONDARY: '#CCCCCC',
  BUTTON_DISABLED_FONT_COLOR: '#888888',

  // button kit color
  BUTTON_DEFAULT_BG_PRIMARY: '#053385',
  BUTTON_HOVER_BG_PRIMARY: '#062e5f',
};

export const COLOR = COLOR_DEFAULT;

export const BOX_SHADOW = {
  PRIMARY: '0 1px 15px rgba(0, 0, 0, 0.1);',
};
