export const USER_ACTIONS = {
  login: {
    success: 'USER/LOGIN_SUCCESS',
    error: 'USER/LOGIN_ERROR',
    setAuthorizedUserInfo: 'USER/SET_AUTHORIZED_USER_INFO',
    setUserAsNotAuthorized: 'USER/SET_USER_AS_NOT_AUTHORIZED',
  },
  registration: {
    request: 'USER/REGISTRATION_REQUEST',
    success: 'USER/REGISTRATION_SUCCESS',
    error: 'USER/REGISTRATION_ERROR',
  },
  update: {
    request: 'USER/UPDATE_REQUEST',
    success: 'USER/UPDATE_SUCCESS',
    error: 'USER/UPDATE_ERROR',
    updateAuthorizedUserInfo: 'USER/UPDATE_AUTHORIZED_USER_INFO',
  },
  switch: {
    request: 'USER/SWITCH_REQUEST',
    success: 'USER/SWITCH_SUCCESS',
    error: 'USER/SWITCH_ERROR',
  },
  request: {
    status: 'USER/REQUEST_STATUS',
  },
  fetchOrganizationInfo: {
    start: 'USER / FETCH_ORGANIZATION_INFO_START',
    success: 'USER / FETCH_ORGANIZATION_INFO_SUCCESS',
    error: 'USER / FETCH_ORGANIZATION_INFO_ERROR',
  },
  matchInfo: {
    request: 'USER/MATCH-INFO_REQUEST',
    success: 'USER/MATCH-INFO_SUCCESS',
    error: 'USER/MATCH-INFO_ERROR',
    modal: 'USER/MATCH-INFO_MODAL',
    resetState: 'USER / MATCH_INFO_RESET',
  },
  tournamentInfo: {
    request: 'USER/TOURNAMENT-INFO_REQUEST',
    success: 'USER/TOURNAMENT-INFO_SUCCESS',
    error: 'USER/TOURNAMENT-INFO_ERROR',
    resetState: 'USER / TOURNAMENT_INFO_RESET',
  },
  freeplayOpen: {
    status: 'USER/FREEPLAY-OPEN_STATUS',
  },
  deleteAccount: 'USER / DELETE_ACCOUNT',
  deleteUserAccount: 'USER / DELETE_USER_ACCOUNT',
  inviteInfo: {
    request: 'USER / INVITE-INFO_REQUEST',
    success: 'USER / INVITE-INFO_SUCCESS',
    error: 'USER / INVITE-INFO_ERROR',
  },
  tournaments: {
    request: 'USER / TOURNAMENTS_REQUEST',
    success: 'USER / TOURNAMENTS_SUCCESS',
    error: 'USER / TOURNAMENTS_ERROR',
    resetState: 'USER / TOURNAMENTS_RESET_STATE',
  },
  matches: {
    request: 'USER / MATCHES_REQUEST',
    success: 'USER / MATCHES_SUCCESS',
    error: 'USER / MATCHES_ERROR',
    resetState: 'USER / MATCHES_RESET_STATE',
  },
  organizations: {
    request: 'USER / ORGANIZATIONS_REQUEST',
    success: 'USER / ORGANIZATIONS_SUCCESS',
    error: 'USER / ORGANIZATIONS_ERROR',
    reset: 'USER / ORGANIZATIONS_RESET',
  },
  accounts: {
    request: 'USER / ACCOUNTS_REQUEST',
    success: 'USER / ACCOUNTS_SUCCESS',
    error: 'USER / ACCOUNTS_ERROR',
    reset: 'USER / ACCOUNTS_RESET',
  },
  uploads: {
    request: 'USER / UPLOADS_REQUEST',
    success: 'USER / UPLOADS_SUCCESS',
    error: 'USER / UPLOADS_ERROR',
  },
  invoices: {
    request: 'USER / INVOICES_REQUEST',
    success: 'USER / INVOICES_SUCCESS',
    error: 'USER / INVOICES_ERROR',
    reset: 'USER / INVOICES_RESET',
  },
  paymentMethods: {
    request: 'USER / PAYMENT_METHODS_REQUEST',
    success: 'USER / PAYMENT_METHODS_SUCCESS',
    error: 'USER / PAYMENT_METHODS_ERROR',
    reset: 'USER / PAYMENT_METHODS_RESET',
  },
};
