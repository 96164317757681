import { LEAGUE_ACTIONS } from './constants';

const initialState = {
  league: {},
  error: null,
  isFetching: false,
  isJoining: false,
  isLeaving: false,
};

export const leagueReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEAGUE_ACTIONS.fetch.request:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case LEAGUE_ACTIONS.fetch.success:
      return {
        ...state,
        league: action.payload,
        isFetching: false,
      };
    case LEAGUE_ACTIONS.fetch.error:
      return {
        ...state,
        league: {},
        error: action.payload,
        isFetching: false,
      };

    case LEAGUE_ACTIONS.join.request:
      return {
        ...state,
        isJoining: true,
      };
    case LEAGUE_ACTIONS.join.success:
      return {
        ...state,
        isJoining: false,
      };
    case LEAGUE_ACTIONS.join.error:
      return {
        ...state,
        isJoining: false,
      };

    case LEAGUE_ACTIONS.leave.request:
      return {
        ...state,
        isLeaving: true,
      };
    case LEAGUE_ACTIONS.leave.success:
      return {
        ...state,
        isLeaving: false,
      };
    case LEAGUE_ACTIONS.leave.error:
      return {
        ...state,
        isLeaving: false,
      };
    case LEAGUE_ACTIONS.playerCount.update:
      return {
        ...state,
        league: {
          ...state.league,
          playerCount: action.payload,
        },
      };
    case LEAGUE_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};
