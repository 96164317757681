import { propValueOr } from '../../helpers/common';
import { MATCHMAKING_ACTIONS } from './constants';

const initialState = {
  queue: '',
  ticket: '',
  status: '',
  coins: null,
  fee: null,
  players: null,
  error: null,
};

export const matchmakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case MATCHMAKING_ACTIONS.set:
      return {
        ...state,
        fee: propValueOr(action, 'payload.fee', ''),
        coins: propValueOr(action, 'payload.coins', ''),
        queue: propValueOr(action, 'payload.queue', ''),
        players: propValueOr(action, 'payload.players', ''),
      };
    case MATCHMAKING_ACTIONS.create.request:
      return {
        ...state,
        status: '',
      };
    case MATCHMAKING_ACTIONS.create.success:
      return {
        ...state,
        ticket: action.payload,
        status: 'WaitingForMatch',
      };
    case MATCHMAKING_ACTIONS.create.error:
      return {
        ...state,
        queue: '',
        ticket: '',
        status: propValueOr(action, 'payload.status', ''),
        error: propValueOr(action, 'payload.error', ''),
      };
    case MATCHMAKING_ACTIONS.status.request:
      return {
        ...state,
      };
    case MATCHMAKING_ACTIONS.status.success:
      return {
        ...state,
        status: propValueOr(action, 'payload.status', ''),
        error: null,
      };
    case MATCHMAKING_ACTIONS.status.error:
      return {
        ...state,
        status: propValueOr(action, 'payload.status', ''),
        error: propValueOr(action, 'payload.error', ''),
      };
    case MATCHMAKING_ACTIONS.cancel.request:
      return {
        ...state,
      };
    case MATCHMAKING_ACTIONS.cancel.success:
      return {
        ...state,
        queue: '',
      };
    case MATCHMAKING_ACTIONS.cancel.error:
      return {
        ...state,
        queue: '',
        error: action.payload,
      };
    case MATCHMAKING_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};
