import { WALLET_ACTIONS } from './constants';
import { api } from '../../index';
import { propValueOr } from '../../helpers/common';

export const fetchWalletAction = () => (dispatch) => {
  dispatch({
    type: WALLET_ACTIONS.wallet.request,
  });

  api.wallet
    .fetch()
    .then((resp) => {
      dispatch({
        type: WALLET_ACTIONS.wallet.success,
        payload: propValueOr(resp, 'data', {}),
      });
    })
    .catch((err) => {
      dispatch({ type: WALLET_ACTIONS.wallet.error });
      throw err;
    });
};

export const fetchTransactionsAction = (query) => (dispatch) => {
  dispatch({
    type: WALLET_ACTIONS.transactions.request,
  });

  api.wallet
    .list(query)
    .then((resp) => {
      dispatch({
        type: WALLET_ACTIONS.transactions.success,
        payload: propValueOr(resp, 'data.docs', []),
      });
    })
    .catch((err) => {
      dispatch({ type: WALLET_ACTIONS.transactions.error });
      throw err;
    });
};

export const performPayoutAction = (amount, email, usdAmount) => (dispatch) => {
  dispatch({
    type: WALLET_ACTIONS.payout.request,
  });

  api.wallet.payout(amount, email, usdAmount).then((resp) => {
    dispatch({
      type: WALLET_ACTIONS.payout.success,
      payload: propValueOr(resp, 'data', null),
    });
  });
};

export const resetWalletStateAction = () => ({
  type: WALLET_ACTIONS.resetState,
});
