export const SEASON_ACTIONS = {
  fetch: {
    request: 'SEASON/FETCH_REQUEST',
    success: 'SEASON/FETCH_SUCCESS',
    error: 'SEASON/FETCH_ERROR',
  },
  join: {
    request: 'SEASON/JOIN_REQUEST',
    success: 'SEASON/JOIN_SUCCESS',
    error: 'SEASON/JOIN_ERROR',
  },
  leave: {
    request: 'SEASON/LEAVE_REQUEST',
    success: 'SEASON/LEAVE_SUCCESS',
    error: 'SEASON/LEAVE_ERROR',
  },
  playerCount: {
    update: 'SEASON/PLAYERCOUNT_UPDATE',
  },
  reset: 'SEASON/RESET_STATE',
};
