import { TOURNAMENT_ACTIONS } from './constants';

const initialState = {
  tournament: {},
  stage: {},
  bracket: {},
  error: null,
  isFetching: false,
  isFetchingBracket: false,
  canJoin: null,
  isJoining: false,
  isLeaving: false,
  canJoinError: null,
  joinError: null,
  leaveError: null,
  team: {},
  isFetchingTeam: false,
  teamError: null,
  refetchBracket: false,
  subscribed: false,
  paid: false,
};

export const tournamentReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_ACTIONS.fetch.request:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case TOURNAMENT_ACTIONS.fetch.success:
      return {
        ...state,
        tournament: action.payload,
        isFetching: false,
      };
    case TOURNAMENT_ACTIONS.fetch.error:
      return {
        ...state,
        tournament: {},
        error: action.payload,
        isFetching: false,
      };
    case TOURNAMENT_ACTIONS.playerCount.update:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          playerCount: action.payload,
        },
      };
    case TOURNAMENT_ACTIONS.subscribed.set:
      return {
        ...state,
        subscribed: action.payload,
      };
    case TOURNAMENT_ACTIONS.bracket.refetch:
      return {
        ...state,
        refetchBracket: action.payload,
      };
    case TOURNAMENT_ACTIONS.bracket.request:
      return {
        ...state,
        error: null,
        isFetchingBracket: true,
      };
    case TOURNAMENT_ACTIONS.bracket.success:
      const stage =
        state.bracket?.id &&
        action.payload.stages?.length > 0 &&
        action.payload.stages.find((s) =>
          (s.brackets || []).map((b) => b.id).includes(state.bracket.id)
        );
      return {
        ...state,
        tournament: {
          ...state.tournament,
          stages: action.payload.stages,
          users: action.payload.users,
        },
        ...(stage ? { bracket: stage.brackets[0] } : {}),
        isFetchingBracket: false,
      };
    case TOURNAMENT_ACTIONS.bracket.error:
      return {
        ...state,
        error: action.payload,
        isFetchingBracket: false,
      };
    case TOURNAMENT_ACTIONS.stage.update:
      return {
        ...state,
        stage: action.payload,
      };
    case TOURNAMENT_ACTIONS.bracket.update:
      return {
        ...state,
        bracket: action.payload,
      };
    case TOURNAMENT_ACTIONS.canJoin.request:
      return {
        ...state,
        canJoinError: null,
      };
    case TOURNAMENT_ACTIONS.canJoin.success:
      return {
        ...state,
        canJoin: action.payload.canJoin,
        tournamentUser: action.payload.tournamentUser,
        winner: action.payload.winner,
      };
    case TOURNAMENT_ACTIONS.canJoin.error:
      return {
        ...state,
        canJoinError: action.payload,
      };
    case TOURNAMENT_ACTIONS.join.request:
      return {
        ...state,
        isJoining: true,
        joinError: null,
      };
    case TOURNAMENT_ACTIONS.join.success:
      return {
        ...state,
        isJoining: false,
      };
    case TOURNAMENT_ACTIONS.join.error:
      return {
        ...state,
        isJoining: false,
        joinError: action.payload,
      };
    case TOURNAMENT_ACTIONS.leave.request:
      return {
        ...state,
        isLeaving: true,
        leaveError: null,
      };
    case TOURNAMENT_ACTIONS.leave.success:
      return {
        ...state,
        isLeaving: false,
      };
    case TOURNAMENT_ACTIONS.leave.error:
      return {
        ...state,
        isLeaving: false,
        leaveError: action.payload,
      };
    case TOURNAMENT_ACTIONS.team.request:
      return {
        ...state,
        isFetchingTeam: true,
        teamError: null,
      };
    case TOURNAMENT_ACTIONS.team.success:
      return {
        ...state,
        team: action.payload,
        isFetchingTeam: false,
      };
    case TOURNAMENT_ACTIONS.team.error:
      return {
        ...state,
        isFetchingTeam: false,
        teamError: action.payload,
      };
    case TOURNAMENT_ACTIONS.team.reset:
      return {
        ...state,
        team: {},
      };
    case TOURNAMENT_ACTIONS.stage.reset:
      return {
        ...state,
        stage: {},
      };
    case TOURNAMENT_ACTIONS.bracket.reset:
      return {
        ...state,
        bracket: {},
      };
    case TOURNAMENT_ACTIONS.setProp:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case TOURNAMENT_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};
