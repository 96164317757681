export const WALLET_ACTIONS = {
  wallet: {
    request: 'WALLET/FETCH_REQUEST',
    success: 'WALLET/FETCH_SUCCESS',
    error: 'WALLET/FETCH_ERROR',
  },
  transactions: {
    request: 'WALLET/LIST_REQUEST',
    success: 'WALLET/LIST_SUCCESS',
    error: 'WALLET/LIST_ERROR',
  },
  resetState: 'WALLET/RESET_STATE',
  payout: {
    request: 'WALLET/PAYOUT_REQUEST',
    success: 'WALLET/PAYOUT_SUCCESS',
    error: 'WALLET/PAYOUT_ERROR',
  },
};
