export const NOTIFICATIONS_ACTIONS = {
  fetch: {
    success: 'NOTIFICATIONS/FETCH_SUCCESS',
    error: 'NOTIFICATIONS/FETCH_ERROR',
  },
  readAll: {
    success: 'NOTIFICATIONS/READ_ALL_SUCCESS',
    error: 'NOTIFICATIONS/READ_ALL_ERROR',
  },
  modal: {
    add: 'NOTIFICATIONS/MODAL_ADD',
    remove: 'NOTIFICATIONS/MODAL_REMOVE',
  },
  append: {
    request: 'NOTIFICATIONS/APPEND_REQUEST',
    success: 'NOTIFICATIONS/APPEND_SUCCESS',
    error: 'NOTIFICATIONS/APPEND_ERROR',
  },
  fetchedAll: 'NOTIFICATIONS/FETCHED_ALL',
};
