export const TOURNAMENT_ACTIONS = {
  fetch: {
    request: 'TOURNAMENT/FETCH_REQUEST',
    success: 'TOURNAMENT/FETCH_SUCCESS',
    error: 'TOURNAMENT/FETCH_ERROR',
  },
  stage: {
    update: 'TOURNAMENT/STAGE_UPDATE',
    reset: 'TOURNAMENT/STAGE_RESET',
  },
  bracket: {
    request: 'TOURNAMENT/BRACKET_REQUEST',
    success: 'TOURNAMENT/BRACKET_SUCCESS',
    error: 'TOURNAMENT/BRACKET_ERROR',
    update: 'TOURNAMENT/BRACKET_UPDATE',
    reset: 'TOURNAMENT/BRACKET_RESET',
    refetch: 'TOURNAMENT/BRACKET_REFETCH',
  },
  playerCount: {
    update: 'TOURNAMENT/PLAYERCOUNT_UPDATE',
  },
  subscribed: {
    set: 'TOURNAMENT/SUBSCRIBED_SET',
  },
  canJoin: {
    request: 'TOURNAMENT/CANJOIN_REQUEST',
    success: 'TOURNAMENT/CANJOIN_SUCCESS',
    error: 'TOURNAMENT/CANJOIN_ERROR',
  },
  join: {
    request: 'TOURNAMENT/JOIN_REQUEST',
    success: 'TOURNAMENT/JOIN_SUCCESS',
    error: 'TOURNAMENT/JOIN_ERROR',
  },
  leave: {
    request: 'TOURNAMENT/LEAVE_REQUEST',
    success: 'TOURNAMENT/LEAVE_SUCCESS',
    error: 'TOURNAMENT/LEAVE_ERROR',
  },
  team: {
    request: 'TOURNAMENT/TEAM_REQUEST',
    success: 'TOURNAMENT/TEAM_SUCCESS',
    error: 'TOURNAMENT/TEAM_ERROR',
    reset: 'TOURNAMENT/TEAM_RESET',
  },
  setProp: 'TOURNAMENT/SET_PROP',
  reset: 'TOURNAMENT/RESET_STATE',
};
