export const buildFilter = (data) => {
  const filter = {
    page: data && data.page ? data.page : 1,
    limit: data && data.limit ? data.limit : 10,
  };
  if (data) {
    if (data.age && data.age !== 'all') {
      filter.fromAge = data.age;
      filter.toAge = data.age + 1;
    }
    if (data.game && data.game !== 'all') {
      filter.gameId = data.game;
    }
    if (data.status && data.status !== 'all') {
      filter.status = data.status;
    }
    if (data.state && data.state !== 'all') {
      filter.state = data.state;
    }
    if (data.type && data.type !== 'all') {
      filter.type = data.type;
    }
    if (data.organizationId) {
      filter.organizationId = data.organizationId;
    }
    if (data.affiliationId) {
      filter.affiliationId = data.affiliationId;
    }
    if (data.consoleId !== undefined) {
      filter.consoleId = data.consoleId;
    }
    if (data.userIds) {
      filter.userIds = data.userIds;
    }
    if (data.users) {
      filter.users = data.users;
    }
    if (data.tournamentId) {
      filter.tournamentId = data.tournamentId;
    }
    if (data.q) {
      filter.q = data.q;
    }
    if (data.name) {
      filter.name = data.name;
    }
    if (data.paginate) {
      filter.paginate = data.paginate;
    }
  }
  return Object.entries(filter)
    .map((item) => `${item[0]}=${item[1]}`)
    .join('&');
};
