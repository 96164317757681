import { CONFIG_ACTIONS, buildConfigObject } from './constants';
import { api } from '../../index';
import { isObjectEmpty, propValueOr } from '../../helpers/common';
import { updateUserRequestStatus } from '../user/actions';
import ReactGA from 'react-ga';

export const fetchConfigAction =
  (slug = '') =>
  (dispatch) => {
    dispatch({
      type: CONFIG_ACTIONS.fetch.request,
    });

    return api.config
      .get(slug)
      .then((resp) => {
        const { assets, ...rest } = propValueOr(resp, 'data', { assets: [] });
        if (
          !!resp.data &&
          !!resp.data.slug &&
          resp.data.slug.toLowerCase() !== 'unaffliated' &&
          resp.data.id !== null
        ) {
          ReactGA.event({
            category: `${resp.data.subdomain} - New Session`,
            action: 'Session Started',
          });
          ReactGA.event({
            category: `${resp.data.subdomain}-${resp.data.slug} New Session`,
            action: 'Session Started',
          });
        }
        if (!resp.data || isObjectEmpty(resp.data)) {
          dispatch({ type: CONFIG_ACTIONS.fetch.empty });
          return resp;
        }

        dispatch({
          type: CONFIG_ACTIONS.fetch.success,
          payload: {
            assets: buildConfigObject(assets),
            ...rest,
          },
        });

        return resp;
      })
      .catch((err) => {
        dispatch({ type: CONFIG_ACTIONS.fetch.error, payload: err });
        dispatch(updateUserRequestStatus(404));
        throw err;
      });
  };

export const resetConfigAction = () => (dispatch) => {
  dispatch({
    type: CONFIG_ACTIONS.reset,
  });
};
