import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { COLOR } from '../../../constants/theme';

export const IconSelectSD = styled.i`
  position: relative;
  display: inline-block;
  width: 6px;
  height: 8px;

  ${({ hiddenIcon }) =>
    !hiddenIcon &&
    css`
      &:before,
      &:after {
        content: '';
        position: absolute;
        border: 3px solid transparent;
        left: 0;
      }

      &:before {
        top: 0;
        border-bottom-color: ${COLOR.FG_PRIMARY};
        border-top: none;
      }

      &:after {
        top: 5px;
        border-top-color: ${COLOR.FG_PRIMARY};
        border-bottom: none;
      }
    `}
`;

IconSelectSD.propType = {
  hiddenIcon: PropTypes.bool,
};
