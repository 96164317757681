import axios from 'axios';
import { USER_ACTIONS } from '../store/user/constants';
import {
  getQueryStringFromObject,
  parseJwt,
  propValueOr,
} from '../helpers/common';
import { setAuthorizedUserInfoAction } from '../store/user/actions';
import { buildFilter } from './util';
import moment from 'moment';

export default class API {
  constructor(store, options = {}) {
    this.store = store;
    this.client =
      options.client ||
      axios.create({
        baseURL: process.env.REACT_APP_DEVELOPMENT + 'api/v1/',
      });
    this.sharingClient =
      options.sharingClient ||
      axios.create({
        baseURL: process.env.REACT_APP_SHARING_SERVER,
      });

    this.token = options.token || window.localStorage.getItem('jwt');
    this.refreshToken =
      options.refreshToken || window.localStorage.getItem('jwt_refresh');

    const authorize = (config) => {
      if (!this.token) {
        return config;
      }

      const newConfig = {
        headers: {},
        ...config,
      };

      newConfig.headers.Authorization = `Bearer ${this.token}`;

      return newConfig;
    };
    this.client.interceptors.request.use(authorize, (err) =>
      Promise.reject(err)
    );
    this.sharingClient.interceptors.request.use(authorize, (err) =>
      Promise.reject(err)
    );

    this.client.interceptors.response.use(
      (res) => res,
      (err) => {
        if (propValueOr(err, 'response.status', null) === 500) {
          this.store.dispatch({
            type: USER_ACTIONS.request.status,
            payload: { status: err.response.status },
          });
        }

        if (propValueOr(err, 'response.status', null) === 401 && !!this.token) {
          throw err;
        }

        if (
          !this.refreshToken ||
          (err.response && err.response.status !== 401) ||
          err.config.retry
        ) {
          throw err;
        }

        throw err;
      }
    );
  }

  setTokens = (token, refreshToken, userInfo) => {
    this.token = token;
    this.refreshToken = refreshToken;
    window.localStorage.setItem('jwt', token);
    window.localStorage.setItem('jwt_refresh', refreshToken);

    // update user information in the redux store.
    this.store.dispatch(setAuthorizedUserInfoAction(parseJwt(token)));
  };

  login({ username, password, fingerprint, organizationId }) {
    return this.client.post(`login`, {
      username,
      password,
      fingerprint,
      organizationId,
    });
  }

  logout() {
    this.token = null;
    this.refreshToken = null;
    window.localStorage.removeItem('jwt');
    window.localStorage.removeItem('jwt_refresh');
    window.location.replace('/login');
  }

  /* Account APIs */
  accounts = {
    update: async (body) => {
      return this.client.put('user/accounts', body);
    },

    delete: async (id) => {
      return this.client.delete(`user/accounts/${id}`);
    },

    list: async () => {
      return this.client.get('user/accounts');
    },
  };

  /* Config APIs */
  config = {
    get: async (slug) => {
      return this.client.post(`config/${slug}`);
    },
  };

  /* Consoles APIs */
  consoles = {
    get: async () => {
      return this.client.get(`consoles`);
    },
  };

  /* Games APIs */
  games = {
    fetch: async (slug) => {
      return this.client.get(`games/${slug}`);
    },
    get: async (data) => {
      let filterQuery = '';
      if (data) {
        const filter = {};
        if (data.consoleId) {
          filter.consoleId = data.consoleId;
        }
        filterQuery = Object.entries(filter)
          .map((item) => `${item[0]}=${item[1]}`)
          .join('&');
      }

      return this.client.get(`games?${filterQuery}`);
    },
  };

  /* Wallet APIs */
  wallet = {
    list: async (query) => {
      return this.client.get(`wallet/transactions?${buildFilter(query)}`);
    },
    fetch: async () => {
      return this.client.get(`wallet`);
    },
    payout: async (amount, email, usdAmount) => {
      return this.client.post(`wallet/payout`, { amount, email, usdAmount });
    },
  };

  /* Invite APIs */
  invite = {
    link: async (organizationId) => {
      return this.client.get('invites/link', {
        params: { organizationId },
      });
    },
    get: async (token) => {
      return this.client.get(`invites/${token}`);
    },
    code: async (token) => {
      return this.client.get(`invites/code/${token}`);
    },
  };

  /* Leaderboard APIs */
  leaderboard = {
    fetch: async (slug, query) => {
      return this.client.get(
        `leaderboard/${slug}${getQueryStringFromObject(query || { limit: 10 })}`
      );
    },
  };

  /* League APIs */
  league = {
    list: async (data) => {
      let query = '';
      query = this.createTournamentFilter(data, query);
      return this.client.get(`leagues?${query}`);
    },
    get: async (token) => {
      return this.client.get(`leagues/${token}`);
    },
    join: async (token, body) => {
      return this.client.post(`leagues/${token}`, body);
    },
    leave: async (token, body) => {
      return this.client.put(`leagues/${token}`, body);
    },
  };

  /* LFC APIs */
  lfc = {
    get: async (code) => {
      return this.client.post(`connect/lfc`, { code });
    },
  };

  /* Lobby APIs */
  lobby = {
    create: async (body) => this.client.post('lobbies', body),
    list: async (data) => {
      let query = '';
      query = this.createTournamentFilter(data, query);
      return this.client.get(`lobbies?${query}`);
    },
    get: async (token) => {
      return this.client.get(`lobbies/${token}`);
    },
    join: async (token, body) => {
      return this.client.post(`lobbies/${token}`, body);
    },
    leave: async (token, body) => {
      return this.client.put(`lobbies/${token}`, body);
    },
  };

  /* Match APIs */
  match = {
    get: async (token, body) => {
      return this.client.post(`matches/${token}`, body);
    },
    update: async (token, body) => {
      return this.client.put(`matches/${token}`, body);
    },
    list: async (params) => {
      return this.client.get('matches', {
        params,
      });
    },
  };

  /* Matchmaking APIs */
  matchmaking = {
    create: async (body) => this.client.post('matchmaking/create', body),
    status: async (params) => this.client.get('matchmaking/status', { params }),
    get: async (params) => this.client.get('matchmaking/get', { params }),
    cancel: async (params) =>
      this.client.delete('matchmaking/cancel', { params }),
    cancelAll: async (body) => this.client.post('matchmaking/cancelAll', body),
  };

  /* MatchTemplate APIs */
  matchTemplate = {
    get: async (slug) => {
      return this.client.get(`matchTemplates/${slug}`);
    },
  };

  notifications = {
    get: () => this.client.get('notifications'),
    readAll: () => this.client.put('notifications'),
  };

  /* Organization APIs */
  organization = {
    get: async (query) => {
      return this.client.get(`organizations${query}`);
    },
    fetch: async (slug) => {
      return this.client.post(`organizations/${slug}`);
    },
    join: async (id, body) => {
      return this.client.put(`organizations/${id}`, body);
    },
    access: async (id, body) => {
      return this.client.put(`organizations/${id}/access`, body);
    },
    tournaments: async (body) => {
      return this.client.post(`organizations/tournaments`, body);
    },
    games: async (body) => {
      return this.client.post(`organizations/games`, body);
    },
  };

  /* Organizations APIs */
  organizations = {
    get: async () => {
      return this.client.get(`organizations`);
    },
  };

  /* Payment APIs */
  payment = {
    create: async (body) => {
      return this.client.post(`payments`, { paymentInfo: body });
    },
    secret: async (body) => {
      return this.client.post(`payments/secret`, body);
    },
  };

  /* Profile APIs */
  profile = {
    get: async (id) => {
      return this.client.get(`user/${id}`);
    },
  };

  /* Season APIs */
  season = {
    list: async (data) => {
      let query = '';
      query = this.createTournamentFilter(data, query);
      return this.client.get(`seasons?${query}`);
    },
    get: async (token) => {
      return this.client.get(`seasons/${token}`);
    },
    join: async (token, body) => {
      return this.client.post(`seasons/${token}`, body);
    },
    leave: async (token, body) => {
      return this.client.put(`seasons/${token}`, body);
    },
  };

  subscriptions = {
    get: async (paymentInfo) =>
      this.client.get('subscriptions', { params: paymentInfo }),
    create: async (paymentInfo) =>
      this.client.post('subscriptions', { paymentInfo: paymentInfo }),
  };

  /* Teams APIs */
  team = {
    create: async (body) => {
      return this.client.post('teams', body);
    },
    update: async (slug, body) => {
      return this.client.post(`teams/${slug}`, body);
    },
    get: async (slug) => {
      return this.client.get(`teams/${slug}`);
    },
    list: async (query) => {
      return this.client.get(`teams?${buildFilter(query)}`);
    },
    delete: async (slug) => {
      return this.client.delete(`teams/${slug}`);
    },
    invites: {
      list: async (slug, query) => {
        return this.client.get(`teams/${slug}/invites?${buildFilter(query)}`);
      },
      create: async (slug, query, body) => {
        return this.client.post(
          `teams/${slug}/invites?${buildFilter(query)}`,
          body
        );
      },
      update: async (slug, token, body) => {
        return this.client.put(`teams/${slug}/invites/${token}`, body);
      },
    },
    users: {
      list: async (slug, query) => {
        return this.client.get(`teams/${slug}/users?${buildFilter(query)}`);
      },
      remove: async (slug, id) => {
        return this.client.delete(`teams/${slug}/users/${id}`);
      },
    },
    games: {
      create: async (slug, body) => {
        return this.client.post(`teams/${slug}/games`, body);
      },
      remove: async (slug, body) => {
        return this.client.delete(`teams/${slug}/games`, { data: body });
      },
    },
  };

  /* Tournament APIs */
  tournament = {
    create: async (body) => {
      return this.client.post('tournaments', body);
    },
    get: async (token) => {
      return this.client.get(`tournaments/${token}`);
    },
    bracket: async (token) => {
      return this.client.get(`tournaments/${token}/bracket`);
    },
    join: async (token, body) => {
      return this.client.post(`tournaments/${token}/join`, body);
    },
    canJoin: async (token) => {
      return this.client.get(`tournaments/${token}/join`);
    },
    update: async (token, body) => {
      return this.client.post(`tournaments/${token}`, body);
    },
    leave: async (token, body) => {
      return this.client.put(`tournaments/${token}`, body);
    },
    teams: {
      get: async (token, teamId, query) => {
        return this.client.get(
          `tournaments/${token}/teams/${teamId}${getQueryStringFromObject(
            query
          )}`
        );
      },
      replace: async (token, teamId, body) => {
        return this.client.put(`tournaments/${token}/teams/${teamId}`, body);
      },
    },
    leaderboard: {
      get: async (token, teamSize, all = undefined) => {
        const params = {};
        if (teamSize) {
          params.teamSize = teamSize;
        }
        if (!!all) {
          params.all = true;
        }
        return this.client.get(`tournaments/${token}/leaderboard`, {
          params: params,
        });
      },
    },
  };

  /* Tournaments APIs */
  tournaments = {
    get: async (query) => {
      return this.client.get(`tournaments?${buildFilter(query)}`);
    },
    list: async (query) => {
      return this.client.get(
        `tournaments?status=${query.status}&${buildFilter(query)}`
      );
    },
  };

  /* User APIs */
  user = {
    get: async (id) => {
      return this.client.get(`user/${id}`);
    },
    fetch: async () => {
      return this.client.get(`user`);
    },
    email: async (email) => {
      return this.client.get(`user/email/${email}`);
    },
    location: async (body) => {
      return this.client.post(`user/location`, body);
    },
    matches: async () => {
      return this.client.get(`user/matches`);
    },
    leagues: async () => {
      return this.client.get(`user/leagues`);
    },
    lobbies: async () => {
      return this.client.get(`user/lobbies`);
    },
    tournaments: async (data) => {
      return this.client.get(
        `user/tournaments?${this.createTournamentFilter(data)}`
      );
    },
    organizations: async () => {
      return this.client.get(`user/organizations`);
    },
    create: async (body) => {
      return this.client.post('user', body).then((resp) => {
        const { data } = resp;
        this.setTokens(data['access_token'], data['refresh_token']);
        return resp;
      });
    },
    update: async (body) => {
      return this.client.put('user', body).then((resp) => {
        const { data } = resp;
        window.localStorage.setItem('jwt', data['access_token']);
        return resp;
      });
    },
    delete: async () => {
      return this.client.delete('user');
    },
  };

  /* User Upload APIs */
  uploads = {
    update: async (body) => {
      return this.client.post('user/uploads', body);
    },
    list: async () => {
      return this.client.get('user/uploads');
    },
  };

  /* Xbox APIs */
  xbox = {
    auth: async (code) => {
      return this.client.get(
        `connect/xbox/callback?code=${code}&callbackUrl=${encodeURI(
          `${process.env.REACT_APP_DEVELOPMENT}api/v1/connect/xbox/callback_redirect`
        )}`
      );
    },
  };

  twitchAuth(code) {
    return this.client.get(
      `connect/twitch/callback?code=${code}&callbackUrl=${encodeURI(
        `${process.env.REACT_APP_DEVELOPMENT}api/v1/connect/twitch/callback_redirect`
      )}`
    );
  }
  forgotPassword(username) {
    return this.client.post('forgot_password', { username });
  }
  resetPassword(key, password) {
    return this.client.post('reset_password', { key, password });
  }

  getSignedImageUploadUrl(params) {
    return this.client.get('s3/sign', { params: params });
  }

  addOrganization(body) {
    return this.client.post('organizations', body);
  }
  searchOrganization(params) {
    return this.client.get('organizations', { params });
  }

  updateTokens(lastRequestConfig) {
    // if some requests fire together
    if (!this.refreshRequest) {
      this.refreshRequest = this.client.post('refresh_token', {
        refresh_token: this.refreshToken,
      });
    }

    return this.refreshRequest
      .then((resp) => {
        this.setTokens(
          propValueOr(resp, 'data.access_token'),
          propValueOr(resp, 'data.refresh_token')
        );

        this.refreshRequest = null;

        if (!lastRequestConfig) {
          return;
        }

        const repeatedRequest = {
          ...lastRequestConfig,
          retry: true,
        };

        return this.client(repeatedRequest);
      })
      .catch(() => {
        this.logout();
      });
  }

  listMatchTemplates(data) {
    let filterQuery = '';
    if (data) {
      const filter = {};
      if (data.queue) {
        filter.queue = data.queue;
      }
      if (data.consoleId) {
        filter.consoleId = data.consoleId;
      }
      if (data.gameId) {
        filter.gameId = data.gameId;
      }
      filterQuery = Object.entries(filter)
        .map((item) => `${item[0]}=${item[1]}`)
        .join('&');
    }
    return this.client.get(`matchTemplates?${filterQuery}`);
  }

  createStripePayment(paymentInfo) {
    return this.client.post('subscriptions', { paymentInfo });
  }

  createPaypalPayment(paymentInfo) {
    return this.client.post('subscriptions', { paymentInfo });
  }

  getInvoices() {
    return this.client.get('payments/list');
  }

  getPaymentMethods() {
    return this.client.get('payments');
  }

  updateStripePayment(paymentInfo) {
    return this.client.put('payments', { paymentInfo });
  }

  getTournaments(data) {
    let filterQuery = '';
    filterQuery = this.createTournamentFilter(data, filterQuery);
    return this.client.get(`tournaments?${filterQuery}`);
  }

  createTournamentFilter(data, filterQuery) {
    if (data) {
      const filter = {
        page: data.page ? data.page : 1,
        limit: data.limit ? data.limit : 10,
      };
      if (data.age && data.age !== 'all') {
        filter.fromAge = data.age;
        filter.toAge = data.age + 1;
      }
      if (data.game && data.game !== 'all') {
        filter.gameId = data.game;
      }
      if (data.matchTemplateId && data.matchTemplateId !== 'all') {
        filter.matchTemplateId = data.matchTemplateId;
      }
      if (data.status && data.status !== 'all') {
        filter.status = data.status;
      }
      if (data.state && data.state !== 'all') {
        filter.state = data.state;
      }
      if (data.type && data.type !== 'all') {
        filter.type = data.type;
      }
      if (data.category) {
        filter.category = data.category;
      }
      if (data.organizationId) {
        filter.organizationId = data.organizationId;
      }
      if (data.affiliationId) {
        filter.affiliationId = data.affiliationId;
      }
      if (data.consoleId !== undefined && data.consoleId !== 'all') {
        filter.consoleId = data.consoleId;
      }
      if (data.featured) {
        filter.featured = data.featured;
      }
      filterQuery = Object.entries(filter)
        .map((item) => `${item[0]}=${item[1]}`)
        .join('&');
    }
    return filterQuery;
  }

  getGames(data) {
    let filterQuery = '';
    if (data) {
      const filter = {};
      if (data.consoleId) {
        filter.consoleId = data.consoleId;
      }
      filterQuery = Object.entries(filter)
        .map((item) => `${item[0]}=${item[1]}`)
        .join('&');
    }

    return this.client.get(`games?${filterQuery}`);
  }

  getLeaderboard(data) {
    const filter = {
      limit: 10,
    };

    if (data.type) {
      filter.type = data.type;
    }

    if (data.gameId) {
      filter.gameId = data.gameId;
    }

    if (data.age !== 'all') {
      filter.fromAge = data.age;
      filter.toAge = data.age + 1;
    }

    if (data.after) {
      filter.after = moment(data.after).format('yyyy-MM-DD');
    }

    if (data.before) {
      filter.before = moment(data.before).format('yyyy-MM-DD');
    }

    if (data.region !== 'all') {
      filter.region = data.region;
    }

    if (data.state !== 'all') {
      filter.state = data.state;
    }

    if (data.affiliationId) {
      filter.affiliationId = data.affiliationId;
    }

    if (data.organizationId) {
      filter.organizationId = data.organizationId;
    }

    const filterQuery = Object.entries(filter)
      .map((item) => `${item[0]}=${item[1]}`)
      .join('&');

    if (filterQuery) {
      return this.client.get(`leaderboard?${filterQuery}`);
    }

    return this.client.get('leaderboard');
  }

  getNotifications() {
    return this.client.get('notifications?limit=25');
  }

  getNotificationsPage(query) {
    return this.client.get(`notifications?${buildFilter(query)}`);
  }

  readAllNotification() {
    return this.client.put('notifications');
  }

  respondMatchInvite(token, body) {
    return this.client.put(`matchInvites/${token}`, body);
  }

  search(query) {
    return this.client.get(`search?${buildFilter(query)}`);
  }

  sendEmail(body) {
    return this.client.post(`contact`, body);
  }

  shareImage(body) {
    return this.sharingClient.post('create', body);
  }

  uploadShareImage(data, policy, onUploadProgress) {
    const formData = new FormData();

    const params = {
      key: policy.folder + policy.filename,
      AWSAccessKeyId: policy.s3key,
      acl: policy.acl,
      policy: policy.policy,
      signature: policy.signature,
      'Content-Type': policy.mimetype,
    };

    for (const [key, value] of Object.entries(params)) {
      formData.append(key, value);
    }

    formData.append('file', data);

    return axios
      .post(policy.bucket_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      })
      .then((resp) => resp);
  }
}
