import { TEAM_ACTIONS } from './constants';

const initialState = {
  team: {},
  list: [],
  total: 1,
  invites: [],
  fetching: false,
  fetchingList: false,
  fetchingInvites: false,
};

const reducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TEAM_ACTIONS.fetch.request:
      return {
        ...state,
        fetching: true,
      };
    case TEAM_ACTIONS.fetch.success:
      return {
        ...state,
        team: payload,
        fetching: false,
      };
    case TEAM_ACTIONS.fetch.error:
      return {
        ...state,
        fetching: false,
      };
    case TEAM_ACTIONS.fetchTeams.request:
      return {
        ...state,
        fetchingList: true,
      };
    case TEAM_ACTIONS.fetchTeams.success:
      return {
        ...state,
        list: payload.docs || payload,
        fetchingList: false,
        total: !!payload.pages ? payload.pages : 1,
      };
    case TEAM_ACTIONS.fetchTeams.error:
      return {
        ...state,
        fetchingList: false,
      };
    case TEAM_ACTIONS.fetchInvites.request:
      return {
        ...state,
        fetchingInvites: true,
      };
    case TEAM_ACTIONS.fetchInvites.success:
      return {
        ...state,
        invites: payload,
        fetchingInvites: false,
      };
    case TEAM_ACTIONS.fetchInvites.error:
      return {
        ...state,
        fetchingInvites: false,
      };
    case TEAM_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
