import { darken } from 'polished';

import { COLOR } from '../../../constants/theme';
import { BUTTON_STATE } from './constants';

export const getButtonAppearanceColor = (props, state) => {
  const { color, appearance } = props;
  const greenHover =
    appearance === 'secondary' ? COLOR.GREEN : darken(0.1, COLOR.GREEN);
  const lightGreenHover =
    appearance === 'secondary' ? COLOR.GREEN : darken(0.1, COLOR.GREEN);
  const redHover =
    appearance === 'secondary' ? COLOR.ROUGE : darken(0.1, COLOR.ROUGE);
  const rivalHover =
    appearance === 'secondary' ? COLOR.RIVAL_RED : darken(0.1, COLOR.RIVAL_RED);
  const cokeHover =
    appearance === 'secondary' ? COLOR.COKE_RED : darken(0.1, COLOR.COKE_RED);
  const whiteHover =
    appearance === 'secondary' ? COLOR.WHITE : darken(0.1, COLOR.WHITE);

  if (state === BUTTON_STATE.DEFAULT) {
    if (color === 'primary') {
      if (appearance === 'secondary') {
        return COLOR.WHITE;
      }
      return COLOR.BUTTON_DEFAULT_BG_PRIMARY;
    }

    if (color === 'secondary') {
      return COLOR.YELLOW_2;
    }

    if (color === 'inverted') {
      return COLOR.WHITE;
    }

    if (color === 'danger') {
      return COLOR.ERROR;
    }

    if (color === 'text-link') {
      return COLOR.TEXT_LINK;
    }

    if (color === 'green') {
      return COLOR.GREEN;
    }

    if (color === 'light-green') {
      return COLOR.LIGHT_GREEN;
    }

    if (color === 'red') {
      if (appearance === 'secondary') {
        return COLOR.WHITE;
      } else {
        return COLOR.ROUGE;
      }
    }

    if (color === 'rival') {
      if (appearance === 'secondary') {
        return COLOR.WHITE;
      } else {
        return COLOR.RIVAL_RED;
      }
    }

    if (color === 'coke') {
      if (appearance === 'secondary') {
        return COLOR.WHITE;
      } else {
        return COLOR.COKE_RED;
      }
    }

    if (color === 'white') {
      if (appearance === 'secondary') {
        return COLOR.WHITE;
      } else {
        return COLOR.WHITE;
      }
    }

    if (color === 'blue') {
      if (appearance === 'secondary') {
        return COLOR.WHITE;
      } else {
        return COLOR.RIVAL_RED;
      }
    }
  }

  if (state === BUTTON_STATE.HOVER) {
    if (color === 'primary') {
      return COLOR.BUTTON_HOVER_BG_PRIMARY;
    }

    if (color === 'secondary') {
      return COLOR.YELLOW;
    }

    if (color === 'inverted') {
      return COLOR.WHITE;
    }

    if (color === 'danger') {
      return COLOR.ROUGE;
    }

    if (color === 'text-link') {
      return COLOR.LIGHT_BLUE;
    }

    if (color === 'green') {
      return greenHover;
    }

    if (color === 'light-green') {
      return lightGreenHover;
    }

    if (color === 'red') {
      return redHover;
    }

    if (color === 'rival') {
      return rivalHover;
    }

    if (color === 'coke') {
      return cokeHover;
    }

    if (color === 'white') {
      return whiteHover;
    }

    if (color === 'blue') {
      return COLOR.RIVAL_RED_2;
    }
  }

  if (state === BUTTON_STATE.ACTIVE) {
    if (color === 'primary') {
      return COLOR.RIVAL_RED_2;
    }

    if (color === 'secondary') {
      return COLOR.YELLOW;
    }

    if (color === 'inverted') {
      return COLOR.WHITE;
    }

    if (color === 'danger') {
      return COLOR.ROUGE;
    }

    if (color === 'text-link') {
      return COLOR.LIGHT_BLUE;
    }

    if (color === 'green') {
      return greenHover;
    }

    if (color === 'light-green') {
      return lightGreenHover;
    }

    if (color === 'red') {
      return COLOR.ROUGE;
    }

    if (color === 'rival') {
      return COLOR.RIVAL_RED;
    }

    if (color === 'coke') {
      return COLOR.COKE_RED;
    }

    if (color === 'white') {
      return COLOR.WHITE;
    }

    if (color === 'blue') {
      return COLOR.RIVAL_RED_2;
    }
  }

  if (state === BUTTON_STATE.DISABLED) {
    if (appearance === 'primary') {
      return COLOR.BUTTON_DISABLED_BG_PRIMARY;
    } else {
      return COLOR.BUTTON_DISABLED_BG_SECONDARY;
    }
  }
};

export const getButtonKitBackgroundColor = (props, state) => {
  const { appearance } = props;

  if (
    appearance === 'secondary' &&
    (state === BUTTON_STATE.DEFAULT || state === BUTTON_STATE.DISABLED)
  ) {
    return 'transparent';
  }

  if (
    appearance === 'inverted' &&
    (state === BUTTON_STATE.DEFAULT || state === BUTTON_STATE.DISABLED)
  ) {
    return 'transparent';
  }

  return getButtonAppearanceColor(props, state);
};

export const getButtonKitBorder = (props, state) => {
  const { appearance } = props;

  if (state === BUTTON_STATE.DISABLED) {
    return `1px solid ${
      appearance === 'primary'
        ? COLOR.BUTTON_DISABLED_BORDER_PRIMARY
        : COLOR.BUTTON_DISABLED_BORDER_SECONDARY
    }`;
  }

  if (appearance === 'primary') {
    return '1px solid transparent;';
  }

  return `1px solid ${getButtonAppearanceColor(props, state)}`;
};

export const getButtonKitFontColor = (props, state) => {
  const { appearance, color } = props;

  if (state === BUTTON_STATE.DISABLED) {
    return COLOR.BUTTON_DISABLED_FONT_COLOR;
  }

  if (color === 'secondary') {
    return COLOR.BLACK;
  }

  if (color === 'inverted') {
    if (state === BUTTON_STATE.HOVER) return COLOR.RIVAL_RED_2;
    else return COLOR.WHITE;
  }

  if (appearance === 'secondary' && state === BUTTON_STATE.DEFAULT) {
    if (
      color === 'red' ||
      color === 'blue' ||
      color === 'rival' ||
      color === 'coke' ||
      color === 'white'
    ) {
      return COLOR.WHITE;
    }
    return getButtonAppearanceColor(props, state);
  }

  if (
    appearance === 'secondary' &&
    color === 'white' &&
    state === BUTTON_STATE.HOVER
  ) {
    return COLOR.BG_PRIMARY;
  }

  return COLOR.WHITE;
};
