import { USER_ACTIONS } from './constants';

const initialState = {
  isRegistering: false,
  verified: false,
  logged: false,
  info: null,
  requestStatus: null,
  isFreeplayOpen: false,

  // for organizationInfo
  organizationInfo: null,
  isSchoolInfoLoading: false,
  matchInfo: null,
  tournamentInfo: null,
  isMatchInfoFetching: false,

  inviteInfo: null,
  isInviteInfoFetching: false,

  // user tournaments
  tournaments: [],
  totalTournaments: null,
  matchModal: null,
  isFetchingTournaments: false,

  //user current matches
  matches: [],
  isFetchingMatches: false,

  modalInstructions: false,
  modalMatchOver: false,

  organizations: [],
  isFetchingOrganizations: false,

  accounts: [],
  uploads: [],
  isFetchingAccounts: false,

  invoices: [],
  isFetchingInvoices: false,

  paymentMethods: [],
  isFetchingPaymentMethods: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.login.setAuthorizedUserInfo:
      return {
        ...state,
        logged: true,
        verified: true,
        info: action.payload.info,
      };
    case USER_ACTIONS.login.setUserAsNotAuthorized:
      return {
        ...initialState,
        logged: false,
        verified: true,
        info: {},
      };
    case USER_ACTIONS.registration.request:
      return {
        ...state,
        isRegistering: true,
      };
    case USER_ACTIONS.registration.success:
      return {
        ...state,
      };
    case USER_ACTIONS.registration.error:
      return {
        ...state,
        isRegistering: false,
      };
    case USER_ACTIONS.update.updateAuthorizedUserInfo:
      return {
        ...state,
        logged: true,
        verified: true,
        info: action.payload.info,
      };
    case USER_ACTIONS.request.status:
      return {
        ...state,
        requestStatus: action.payload.status,
      };

    case USER_ACTIONS.fetchOrganizationInfo.start:
      return {
        ...state,
        isSchoolInfoLoading: true,
      };

    case USER_ACTIONS.fetchOrganizationInfo.success:
      return {
        ...state,
        isSchoolInfoLoading: false,
        organizationInfo: action.payload.organizationInfo,
      };

    case USER_ACTIONS.fetchOrganizationInfo.error:
      return {
        ...state,
        isSchoolInfoLoading: false,
      };
    case USER_ACTIONS.matchInfo.modal:
      return {
        ...state,
        matchModal: action.payload.token,
      };

    case USER_ACTIONS.matchInfo.request:
      return {
        ...state,
        isMatchInfoFetching: true,
      };
    case USER_ACTIONS.matchInfo.success:
      return {
        ...state,
        isMatchInfoFetching: false,
        matchInfo: action.payload.matchInfo,
      };
    case USER_ACTIONS.matchInfo.error:
      return {
        ...state,
        isMatchInfoFetching: false,
      };
    case USER_ACTIONS.matchInfo.resetState:
      return {
        ...state,
        matchInfo: null,
      };
    case USER_ACTIONS.tournamentInfo.request:
      return {
        ...state,
        isTournamentInfoFetching: true,
      };
    case USER_ACTIONS.tournamentInfo.success:
      return {
        ...state,
        isTournamentInfoFetching: false,
        tournamentInfo: action.payload,
      };
    case USER_ACTIONS.tournamentInfo.error:
      return {
        ...state,
        isTournamentInfoFetching: false,
      };
    case USER_ACTIONS.tournamentInfo.resetState:
      return {
        ...state,
        tournamentInfo: null,
      };
    case USER_ACTIONS.freeplayOpen.status:
      return {
        ...state,
        isFreeplayOpen: action.payload,
      };
    case USER_ACTIONS.inviteInfo.request:
      return {
        ...state,
        isInviteInfoFetching: true,
      };
    case USER_ACTIONS.inviteInfo.success:
      return {
        ...state,
        isInviteInfoFetching: false,
        inviteInfo: action.payload.inviteInfo,
      };
    case USER_ACTIONS.inviteInfo.error:
      return {
        ...state,
        isInviteInfoFetching: false,
      };
    case USER_ACTIONS.tournaments.request:
      return {
        ...state,
        isFetchingTournaments: true,
      };
    case USER_ACTIONS.tournaments.success:
      return {
        ...state,
        isFetchingTournaments: false,
        tournaments: action.payload.docs,
        totalTournaments: action.payload.total,
      };
    case USER_ACTIONS.tournaments.error:
      return {
        ...state,
        isFetchingTournaments: false,
      };
    case USER_ACTIONS.tournaments.resetState:
      return {
        ...state,
        tournaments: initialState.tournaments,
        totalTournaments: initialState.totalTournaments,
      };

    case USER_ACTIONS.matches.request:
      return {
        ...state,
        isFetchingMatches: true,
      };
    case USER_ACTIONS.matches.success:
      return {
        ...state,
        isFetchingMatches: false,
        matches: action.payload,
      };
    case USER_ACTIONS.matches.error:
      return {
        ...state,
        isFetchingMatches: false,
      };
    case USER_ACTIONS.matches.resetState:
      return {
        ...state,
        matches: initialState.matches,
      };
    case USER_ACTIONS.organizations.request:
      return {
        ...state,
        isFetchingOrganizations: true,
      };
    case USER_ACTIONS.organizations.success:
      return {
        ...state,
        isFetchingOrganizations: false,
        organizations: action.payload,
      };
    case USER_ACTIONS.organizations.error:
      return {
        ...state,
        isFetchingOrganizations: false,
      };
    case USER_ACTIONS.organizations.reset:
      return {
        ...state,
        organizations: initialState.organizations,
      };
    case USER_ACTIONS.accounts.request:
      return {
        ...state,
        isFetchingAccounts: true,
      };
    case USER_ACTIONS.accounts.success:
      return {
        ...state,
        isFetchingAccounts: false,
        accounts: action.payload,
      };
    case USER_ACTIONS.accounts.error:
      return {
        ...state,
        isFetchingAccounts: false,
      };
    case USER_ACTIONS.uploads.request:
      return {
        ...state,
      };
    case USER_ACTIONS.uploads.success:
      return {
        ...state,
        uploads: action.payload,
      };
    case USER_ACTIONS.uploads.error:
      return {
        ...state,
      };
    case USER_ACTIONS.accounts.reset:
      return {
        ...state,
        accounts: initialState.accounts,
      };
    case USER_ACTIONS.invoices.fetch:
      return {
        ...state,
        isFetchingInvoices: true,
      };
    case USER_ACTIONS.invoices.success:
      return {
        ...state,
        invoices: action.payload,
        isFetchingInvoices: false,
      };
    case USER_ACTIONS.invoices.error:
      return {
        ...state,
        isFetchingInvoices: false,
      };
    case USER_ACTIONS.invoices.reset:
      return {
        ...state,
        invoices: initialState.invoices,
        isFetchingInvoices: initialState.isFetchingInvoices,
      };
    case USER_ACTIONS.paymentMethods.fetch:
      return {
        ...state,
        isFetchingPaymentMethods: true,
      };
    case USER_ACTIONS.paymentMethods.success:
      return {
        ...state,
        paymentMethods: action.payload,
        isFetchingPaymentMethods: false,
      };
    case USER_ACTIONS.paymentMethods.error:
      return {
        ...state,
        isFetchingPaymentMethods: false,
      };
    case USER_ACTIONS.paymentMethods.reset:
      return {
        ...state,
        paymentMethods: initialState.paymentMethods,
        isFetchingPaymentMethods: initialState.isFetchingPaymentMethods,
      };
    default:
      return state;
  }
};

export default reducer;
