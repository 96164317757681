import { NOTIFICATIONS_ACTIONS } from './constants';

const initialState = {
  wasLoaded: false,
  list: [],
  modal: [],
  fetchedAll: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATIONS_ACTIONS.fetch.success:
      return {
        ...state,
        wasLoaded: true,
        list: payload,
      };

    case NOTIFICATIONS_ACTIONS.readAll.success:
      return {
        ...state,
        list: state.list.map((item) => ({ ...item, read: new Date() })),
      };

    case NOTIFICATIONS_ACTIONS.modal.add:
      return {
        ...state,
        modal: [...state.modal, payload],
      };

    case NOTIFICATIONS_ACTIONS.modal.remove:
      let newArray = state.modal.slice();
      newArray.splice(0, 1);

      return {
        ...state,
        modal: newArray,
      };
    case NOTIFICATIONS_ACTIONS.append.request:
      return {
        ...state,
        error: null,
      };
    case NOTIFICATIONS_ACTIONS.append.success:
      return {
        ...state,
        list: [...state.list, ...payload.addition],
        page: payload.page,
      };
    case NOTIFICATIONS_ACTIONS.append.error:
      return {
        ...state,
        list: [],
        error: payload,
      };
    case NOTIFICATIONS_ACTIONS.fetchedAll:
      return {
        ...state,
        fetchedAll: true,
      };
    default:
      return state;
  }
};

export const getGlobalNotificationsState = (state) => state.notifications;

export default reducer;
