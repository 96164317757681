export const TEAM_ACTIONS = {
  fetch: {
    request: 'TEAM/FETCH_REQUEST',
    success: 'TEAM/FETCH_SUCCESS',
    error: 'TEAM/FETCH_ERROR',
  },
  fetchTeams: {
    request: 'TEAM/FETCH_TEAMS_REQUEST',
    success: 'TEAM/FETCH_TEAMS_SUCCESS',
    error: 'TEAM/FETCH_TEAMS_ERROR',
  },
  fetchInvites: {
    request: 'TEAM/FETCH_INVITES_REQUEST',
    success: 'TEAM/FETCH_INVITES_SUCCESS',
    error: 'TEAM/FETCH_INVITES_ERROR',
  },
  reset: 'TEAM/RESET_STATE',
};
