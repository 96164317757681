import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './user/reducer';
import { configReducer } from './config/reducers';
import { tournamentsReducer } from './tournaments/reducer';
import { tournamentReducer } from './tournament/reducers';
import { standingsReducer } from './standings/reducer';
import { gamesReducer } from './games/reducer';
import { walletReducer } from './wallet/reducer';
import notificationsReducer from './notifications/reducer';
import { gameReducer } from './game/reducers';
import { matchmakingReducer } from './matchmaking/reducers';
import teamReducer from './team/reducers';
import { arenasReducer } from './arenas/reducers';
import { consolesReducer } from './consoles/reducer';
import { lobbyReducer } from './lobby/reducers';
import LogRocket from 'logrocket';
import { leagueReducer } from './league/reducers';
import { leaguesReducer } from './leagues/reducers';
import { seasonReducer } from './season/reducers';

const store = function () {
  const reducers = {
    user: userReducer,
    configState: configReducer,
    tournaments: tournamentsReducer,
    tournamentState: tournamentReducer,
    teamState: teamReducer,
    game: gameReducer,
    leagueState: leagueReducer,
    seasonState: seasonReducer,
    leaguesState: leaguesReducer,
    lobbyState: lobbyReducer,
    matchmakingState: matchmakingReducer,
    games: gamesReducer,
    consoles: consolesReducer,
    walletState: walletReducer,
    standings: standingsReducer,
    notifications: notificationsReducer,
    // reducers for route components
    arenasState: arenasReducer,
  };

  const middleware = [thunk, LogRocket.reduxMiddleware()];

  // In development, use the browser's Redux dev tools extension if installed
  const isDevelopment = process.env.NODE_ENV === 'development';

  // use the old version (2.15.5) of the redux dev tools extension, because version 2.16 has issue
  // https://github.com/zalmoxisus/redux-devtools-extension/issues/588

  // noinspection JSUnresolvedVariable
  const composeEnhancers =
    (isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const rootReducer = combineReducers(reducers);

  return createStore(
    rootReducer,
    // initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );
};

export default store;
