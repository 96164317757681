export const MATCHMAKING_ACTIONS = {
  create: {
    request: 'MATCHMAKING/CREATE_REQUEST',
    success: 'MATCHMAKING/CREATE_SUCCESS',
    error: 'MATCHMAKING/CREATE_ERROR',
  },
  status: {
    request: 'MATCHMAKING/STATUS_REQUEST',
    success: 'MATCHMAKING/STATUS_SUCCESS',
    error: 'MATCHMAKING/STATUS_ERROR',
  },
  get: {
    request: 'MATCHMAKING/GET_REQUEST',
    success: 'MATCHMAKING/GET_SUCCESS',
    error: 'MATCHMAKING/GET_ERROR',
  },
  cancel: {
    request: 'MATCHMAKING/CANCEL_REQUEST',
    success: 'MATCHMAKING/CANCEL_SUCCESS',
    error: 'MATCHMAKING/CANCEL_ERROR',
  },
  set: 'MATCHMAKING/SET_STATE',
  reset: 'MATCHMAKING/RESET_STATE',
};
