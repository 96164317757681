import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { LoaderSD } from './styled';
import Blank from '../Blank/Blank';
import Logo from '../../../static/icons/rival-shield.svg';

const Loader = ({ children, isBlock }) => {
  const renderSpinner = () => (
    <LoaderSD>
      <div className="hlstd--spinner-container">
        <div className="hlstd--spinner-inner" />
        <div className="hlstd--spinner-outer" />
        <div className="hlstd--spinner-logo">
          <img src={Logo} width={50} alt={'rival'} />
        </div>
      </div>
      <div className="hlstd--spinner-footer">
        <div style={{ color: '#841f18' }}>{children}</div>
      </div>
    </LoaderSD>
  );

  return isBlock ? <Blank>{renderSpinner()}</Blank> : renderSpinner();
};

Loader.propTypes = {
  children: PropTypes.node,
  isBlock: PropTypes.bool,
};

export default Loader;
