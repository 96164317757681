import { get, sortBy, set, cloneDeep, isEqual } from 'lodash';
import loginSide from '../../static/images/config/login/side.png';
import registrationSide from '../../static/images/config/registration/side.png';
import banner from '../../static/images/home-hero-bg-new.png';
import skills from '../../static/images/how-it-works/kid-gamer.png';
import sports from '../../static/images/how-it-works/sports.png';
import esports from '../../static/images/how-it-works/esports-arena.png';
import prizes from '../../static/images/how-it-works/prizes.png';
import footer from '../../static/images/bg-footer-bottom.png';
import dbBanner from '../../static/images/config/dashboard/banner.png';
import featured from '../../static/images/config/dashboard/featured.png';

export const CONFIG_ACTIONS = {
  fetch: {
    request: 'CONFIG/FETCH_REQUEST',
    success: 'CONFIG/FETCH_SUCCESS',
    error: 'CONFIG/FETCH_ERROR',
    empty: 'CONFIG/FETCH_EMPTY',
  },
  reset: 'CONFIG/RESET_STATE',
};

export const DEFAULT_CONFIG = {
  login: {
    side: {
      image: loginSide,
    },
  },
  registration: {
    side: {
      image: registrationSide,
    },
  },
  landingpage: {
    banner: {
      title: "IT'S GAME TIME\n ENTER. WIN. EARN CASH.",
      image: banner,
      mobile: {
        image: banner,
      },
    },
    paragraphs: [
      {
        title: 'HOW IT WORKS',
        description:
          'Rival is the 1st platform of its kind that brings together a multitude of amateur esports leagues & tournaments into one platform for everyday gamers. Xbox and PlayStation (Summer 2020) users can join and compete for an array of prizes.',
      },
      {
        title: 'Develop Your Skills',
        description:
          'Rival is fully integrated with Xbox and PlayStation (Summer 2020) profiles and stats, unlocking the ability to deliver your player development data to collegiate and pro recruiters.',
        image: skills,
      },
      {
        title: 'Putting the Sports Back in Esports',
        description: 'All sports. All the time.',
        image: sports,
      },
      {
        title: 'So Many Titles to Win',
        description:
          'Play to win your division, club, city, region, state, or even your country.',
        image: esports,
      },
      {
        title: 'Win Cool Stuff',
        description:
          'Don’t just play to win, play to win cool stuff! From Rival coins to prize pools to VIP experiences, there’s always a way to make your gameplay count.',
        image: prizes,
      },
    ],
    footer: {
      image: footer,
    },
    graphic: {},
    sponsor: {},
  },
  dashboard: {
    banner: {
      image: dbBanner,
    },
    slider: [
      {
        image: featured,
        title: 'Welcome to Rival!',
        description: `Check out the Game Center below and start playing games and tournaments to win prizes!`,
      },
    ],
  },
  gamecenter: {},
  leaderboard: {},
  profile: {},
  social: {},
  logo: {},
  store: {},
};

const removeNullProperties = (ob) =>
  Object.keys(ob).forEach((key) => ob[key] == null && delete ob[key]);

export const buildConfigObject = (items) => {
  let config = cloneDeep(DEFAULT_CONFIG);

  if (!Array.isArray(items)) {
    return config;
  }

  items = sortBy(items, 'name');

  items.forEach((item) => {
    const { name, order, ...rest } = item;
    const propNames = name.split('-');
    const length = propNames.length;

    removeNullProperties(rest); // remove all unused properties.

    //if only one property set to config property directly.
    const obVal = { ...rest };

    if (length === 1) {
      // if first level item... just set value in config
      config[name] = obVal;
    } else if (length > 1) {
      // else if array item or nested item...
      const index = !!order ? order : parseInt(propNames[length - 1]);

      if (!isNaN(index) && index > 0) {
        //if number, we have array
        const propString = propNames.slice(0, length - 1).join('.');

        // if first item in array, create new array with ob, else add to existing
        const arr = isEqual(
          get(DEFAULT_CONFIG, propString, []),
          get(config, propString, [])
        )
          ? []
          : get(config, propString, []);

        arr[index - 1] = obVal;
        set(config, propString, [...arr]);
      } else {
        // if not number... just set the value
        const propString = propNames.join('.');

        set(config, propString, obVal);
      }
    }
  });

  return config;
};
