import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { addUnit } from '../../../helpers/common';
import icon from '../../../static/icons/icon-new-window.svg';

export const SVGIconSD = styled.svg`
  width: ${({ width }) => addUnit(width)};
  height: auto;
`;
SVGIconSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
SVGIconSD.defaultProps = {
  width: 20,
};

export const SVGIconPreloaderSD = styled(SVGIconSD)`
  width: ${({ width }) => addUnit(width)};
  height: auto;
`;
SVGIconPreloaderSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
SVGIconPreloaderSD.defaultProps = {
  width: 20,
};

export const Icon = styled.img.attrs({ src: icon })`
  width: 12px;
	height: 12px;
	${({ width }) =>
    !width &&
    css`
      width: auto;
    `}
	${({ height }) =>
    !height &&
    css`
      height: auto;
    `}
	${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : ''}
`;
