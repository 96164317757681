import React from 'react';
import PropTypes from 'prop-types';
import { SVGIconSD } from './styled';

function IconChecked(props) {
  const { width, fill } = props;
  return (
    <SVGIconSD viewBox="0 0 14 12" width={width} fill={fill}>
      <g transform="translate(-170, -14)">
        <g id="Button">
          <g transform="translate(15.000000, 10.000000)">
            <g transform="translate(154.000000, 2.000000)">
              <path
                d="M13.7,5.1l-6.9,7.2c-0.4,0.5-1.2,0.5-1.6,0l-2.9-3c-0.4-0.4-0.4-1.2,0-1.6c0.4-0.5,1.2-0.5,1.6,0L6,9.8
								l6.1-6.4c0.4-0.5,1.2-0.5,1.6,0C14.1,3.9,14.1,4.7,13.7,5.1"
              />
            </g>
          </g>
        </g>
      </g>
    </SVGIconSD>
  );
}

IconChecked.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default IconChecked;
