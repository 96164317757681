import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from '../../../constants/theme';

const ITEM_HOVER_STYLES = css`
  text-transform: none;

  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${COLOR.BG_ACCENT};
    cursor: pointer;
  }
`;
const ITEM_ACTIVE_STYLES = css`
  opacity: 0.5;
  cursor: default;
`;
const _ifWithItemHoverProps = props =>
  props.withItemHover && !props.isActive && ITEM_HOVER_STYLES;
const _ifItemIsActive = props => props.isActive && ITEM_ACTIVE_STYLES;

export const MenuKitSD = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 100%;
  background-color: ${COLOR.BG_SECONDARY};
`;

export const MenuItemKitSD = styled.li`
  position: relative;
  list-style: none;

  ${_ifItemIsActive}
  ${_ifWithItemHoverProps}
`;
MenuItemKitSD.propTypes = {
  isActive: PropTypes.bool,
  withItemHover: PropTypes.bool,
};
MenuItemKitSD.defaultProps = {
  withItemHover: true,
};

export const MenuItemLinkKitSD = styled.span`
  display: block;
  padding: 16px 16px 14px;

  color: ${COLOR.TEXT_2};
  font: ${FONT_WEIGHT.BOLD} 13px / 1.2 ${FONT_FAMILY.THIN};
  text-decoration: none;
`;
