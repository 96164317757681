export const TOURNAMENTS_ACTIONS = {
  tournaments: {
    request: 'TOURNAMENTS/FETCH_REQUEST',
    success: 'TOURNAMENTS/FETCH_SUCCESS',
    error: 'TOURNAMENTS/FETCH_ERROR',
    appendRequest: 'TOURNAMENTS/APPEND_REQUEST',
    appendSuccess: 'TOURNAMENTS/APPEND_SUCCESS',
    appendError: 'TOURNAMENTS/APPEND_ERROR',
  },
  featured: {
    request: 'FEATURED_TOURNAMENTS/FETCH_REQUEST',
    success: 'FEATURED_TOURNAMENTS/FETCH_SUCCESS',
    error: 'FEATURED_TOURNAMENTS/FETCH_ERROR',
  },
  tournament: {
    request: 'TOURNAMENT / FETCH_REQUEST',
    success: 'TOURNAMENT / FETCH_SUCCESS',
    error: 'TOURNAMENTS / FETCH_ERROR',
  },
  fetchMatchesOfCurrentTournament: {
    start: 'TOURNAMENTS / FETCH_MATCHES_START',
    success: 'TOURNAMENTS / FETCH_MATCHES_SUCCESS',
    error: 'TOURNAMENTS / FETCH_MATCHES_ERROR',
  },
  resetMatchesOfCurrentTournament:
    'TOURNAMENTS / RESET_MATCHES_OF_CURRENT_TOURNAMENT',
  resetState: 'TOURNAMENTS/RESET_STATE',
};
