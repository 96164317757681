export const GAMES_ACTIONS = {
  games: {
    request: 'GAMES/FETCH_REQUEST',
    success: 'GAMES/FETCH_SUCCESS',
    error: 'GAMES/FETCH_ERROR',
  },
  templates: {
    request: 'GAMES/TEMPLATES_FETCH_REQUEST',
    success: 'TEMPLATES_GAMES/FETCH_SUCCESS',
    error: 'TEMPLATES_GAMES/FETCH_ERROR',
  },
  resetState: 'GAMES/RESET_STATE',
};
