export const LOBBY_ACTIONS = {
  fetch: {
    request: 'LOBBY/FETCH_REQUEST',
    success: 'LOBBY/FETCH_SUCCESS',
    error: 'LOBBY/FETCH_ERROR',
  },
  join: {
    request: 'LOBBY/JOIN_REQUEST',
    success: 'LOBBY/JOIN_SUCCESS',
    error: 'LOBBY/JOIN_ERROR',
  },
  leave: {
    request: 'LOBBY/LEAVE_REQUEST',
    success: 'LOBBY/LEAVE_SUCCESS',
    error: 'LOBBY/LEAVE_ERROR',
  },
  playerCount: {
    update: 'LOBBY/PLAYERCOUNT_UPDATE',
  },
  reset: 'LOBBY/RESET_STATE',
};
