import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './store';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import API from './api/index';
import { GlobalStyleSD } from './app/styled';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import WebFont from 'webfontloader';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Pusher from 'pusher-js';

/* Added carousel styling to app */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import './style.css';

export const store = configureStore();
export const api = new API(store);
export const pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || '', {
  cluster: 'us3',
  forceTLS: true,
});

/* Google Analytics */
const tagManagerArgs = {
  gtmId: 'G-FYVJW97VPR',
};

WebFont.load({
  google: {
    families: ['Barlow+Condensed:300,400,500,600,700', 'sans-serif'],
  },
});

if (process.env.REACT_APP_LOG_ROCKET) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET);
  setupLogRocketReact(LogRocket);
}

if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize('UA-145028706-4');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyleSD
      development={process.env.REACT_APP_IS_PRODUCTION !== 'true'}
    />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
