import { USER_ACTIONS } from './constants';
import { parseJwt, propValueOr } from '../../helpers/common';
import { api, pusher } from '../../index';

export const loginAction = (body) => (dispatch) => {
  return api
    .login(body)
    .then((resp) => {
      dispatch(
        setLoggedInAction(resp.data['access_token'], resp.data['refresh_token'])
      );
      return resp;
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.login.error });
      throw err;
    });
};

export const setLoggedInAction = (accessToken, refreshToken) => (dispatch) => {
  api.setTokens(accessToken, refreshToken);
  dispatch({ type: USER_ACTIONS.login.success });
  dispatch(setAuthorizedUserInfoAction(parseJwt(accessToken)));
  const user = parseJwt(accessToken);
  if (user?.id) {
    pusher.subscribe(`user-${user?.id}`);
  }
};

export const setAuthorizedUserInfoAction =
  (info = {}, callback = () => {}) =>
  (dispatch, getState) => {
    dispatch({
      type: USER_ACTIONS.login.setAuthorizedUserInfo,
      payload: { info: info },
    });

    dispatch(fetchUserOrganizationsAction());
    dispatch(fetchUserAccountsAction());
    dispatch(fetchUserUploadsAction());
    callback();
  };

export const updateUserInfoAction = (body) => (dispatch) => {
  return api.user
    .update(body)
    .then((resp) => {
      const accessToken = resp.data['access_token'];

      dispatch({ type: USER_ACTIONS.update.success });
      dispatch(setAuthorizedUserInfoAction(parseJwt(accessToken)));
      return resp;
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.update.error });
      throw err;
    });
};

export const updateUserOrganizationAction = (body) => (dispatch) => {
  dispatch({ type: USER_ACTIONS.switch.request });
  return api.user
    .update(body)
    .then((resp) => {
      const accessToken = resp.data['access_token'];

      dispatch(
        setAuthorizedUserInfoAction(parseJwt(accessToken)),
        dispatch({ type: USER_ACTIONS.switch.success, payload: body })
      );
      return resp;
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.switch.error });
      throw err;
    });
};

export const updateUserAccountAction = (body) => (dispatch) => {
  return api.accounts
    .update(body)
    .then((resp) => {
      const accessToken = resp.data['access_token'];
      api.token = accessToken;

      dispatch({ type: USER_ACTIONS.update.success });
      dispatch(setAuthorizedUserInfoAction(parseJwt(accessToken)));
      return resp;
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.update.error });
      throw err;
    });
};

export const deleteUserAccountAction = (id) => (dispatch) => {
  return api.accounts
    .delete(id)
    .then((resp) => {
      const accessToken = resp.data['access_token'];

      dispatch({
        type: USER_ACTIONS.deleteUserAccount,
      });
      dispatch(setAuthorizedUserInfoAction(parseJwt(accessToken)));
      return resp;
    })
    .catch((err) => {
      console.log('Error in Deleting Account:');
      console.log(err);
    });
};

export const registrationAction = (body) => (dispatch) => {
  dispatch({
    type: USER_ACTIONS.registration.request,
  });
  return api.user
    .create(body)
    .then((resp) => {
      dispatch({ type: USER_ACTIONS.registration.success });
      return resp;
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.registration.error });
      throw err;
    });
};

export const logoutAction = () => {
  api.logout();

  return setUserAsNotAuthorizedAction();
};

export const setUserAsNotAuthorizedAction = () => ({
  type: USER_ACTIONS.login.setUserAsNotAuthorized,
});

export const matchInfoResetStateAction = () => ({
  type: USER_ACTIONS.matchInfo.resetState,
});
export const tournamentInfoResetStateAction = () => ({
  type: USER_ACTIONS.tournamentInfo.resetState,
});

export const updateUserRequestStatus = (status) => ({
  type: USER_ACTIONS.request.status,
  payload: { status: status },
});

export const setFreeplayOpen = (status) => (dispatch) => {
  dispatch({
    type: USER_ACTIONS.freeplayOpen.status,
    payload: status,
  });
};

export const fetchInviteInfoAction = (inviteId) => (dispatch) => {
  dispatch({ type: USER_ACTIONS.inviteInfo.request });

  return api.invite
    .get(inviteId)
    .then((resp) => {
      dispatch({
        type: USER_ACTIONS.inviteInfo.success,
        payload: {
          inviteInfo: {
            token: inviteId,
            ...propValueOr(resp, 'data.invite', {}),
          },
        },
      });

      return resp;
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.inviteInfo.error });
      throw err;
    });
};

export const fetchUserTournamentsAction = (data) => (dispatch) => {
  dispatch({
    type: USER_ACTIONS.tournaments.request,
  });

  return api.user
    .tournaments(data)
    .then((resp) => {
      dispatch({
        type: USER_ACTIONS.tournaments.success,
        payload: resp.data,
      });

      return propValueOr(resp, 'data');
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.tournaments.error });
      throw err;
    });
};

export const resetUserTournamentsStateAction = () => ({
  type: USER_ACTIONS.tournaments.resetState,
});

export const fetchUserMatchesAction = () => (dispatch) => {
  dispatch({
    type: USER_ACTIONS.matches.request,
  });

  return api.user
    .matches()
    .then((resp) => {
      dispatch({
        type: USER_ACTIONS.matches.success,
        payload: propValueOr(resp, 'data'),
      });
      return propValueOr(resp, 'data');
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.matches.error });
      throw err;
    });
};

export const toggleMatchModalAction = (token) => ({
  payload: { token: token },
  type: USER_ACTIONS.matchInfo.modal,
});

export const deleteAccountAction = () => (dispatch) => {
  dispatch({ type: USER_ACTIONS.deleteAccount });
  api.user.delete().then(() => dispatch(logoutAction()));
};

export const fetchUserOrganizationsAction = () => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.organizations.request,
  });
  return api.user
    .organizations()
    .then((resp) => {
      const organizations = propValueOr(resp, 'data', []);

      dispatch({
        type: USER_ACTIONS.organizations.success,
        payload: organizations,
      });
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.organizations.error, payload: err });
    });
};

export const fetchUserAccountsAction = () => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.accounts.request,
  });
  return api.accounts
    .list()
    .then((resp) => {
      const accounts = propValueOr(resp, 'data', []);

      dispatch({
        type: USER_ACTIONS.accounts.success,
        payload: accounts,
      });
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.accounts.error, payload: err });
    });
};

export const fetchUserUploadsAction = () => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.uploads.request,
  });
  return api.uploads
    .list()
    .then((resp) => {
      const uploads = propValueOr(resp, 'data', []);

      dispatch({
        type: USER_ACTIONS.uploads.success,
        payload: uploads,
      });
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.uploads.error, payload: err });
    });
};

export const fetchUserInvoicesAction = () => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.invoices.request,
  });
  return api
    .getInvoices()
    .then((resp) => {
      const invoices = propValueOr(resp, 'data', []);

      dispatch({
        type: USER_ACTIONS.invoices.success,
        payload: invoices,
      });
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.invoices.error, payload: err });
    });
};

export const fetchUserPaymentMethodsAction = () => async (dispatch) => {
  dispatch({
    type: USER_ACTIONS.paymentMethods.request,
  });
  return api
    .getPaymentMethods()
    .then((resp) => {
      const paymentMethods = propValueOr(resp, 'data', []);

      dispatch({
        type: USER_ACTIONS.paymentMethods.success,
        payload: paymentMethods,
      });
    })
    .catch((err) => {
      dispatch({ type: USER_ACTIONS.paymentMethods.error, payload: err });
    });
};
