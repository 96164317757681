import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { SVGIconSD } from '../../Icon/styled';
import {
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
} from '../../../../constants/theme';
import { addUnit } from '../../../../helpers/common';

export const DateTimePickerFieldKitSD = styled(DatePicker)`
  height: 40px;
  padding: 5px 40px 5px 10px;

  background-color: ${COLOR.BG_INPUT};
  border: 1px solid ${({ hasError }) => (hasError ? COLOR.ERROR : COLOR.BORDER)};
  border-radius: 2px;

  color: ${COLOR.TEXT_2};
  font: ${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.FIELD}px / 1 ${FONT_FAMILY.THIN};
  letter-spacing: 0.4px;

  transition: all 0.3s;

  &::placeholder {
    color: ${COLOR.GREY_2};
    font-weight: ${FONT_WEIGHT.NORMAL};
  }

  &:focus {
    /* border-color: ${COLOR.WHITE}; */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    /* background-color: ${COLOR.WHITE}; */
  }
`;
DateTimePickerFieldKitSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullWidth: PropTypes.bool,
  hasError: PropTypes.bool,
};
DateTimePickerFieldKitSD.defaultProps = {
  width: 200,
};

export const DateTimePickerKitSD = styled.div`
  position: relative;
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : addUnit(width))};

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
    width: 100%;
    background-color: ${COLOR.BG_SECONDARY};
    color: ${COLOR.TEXT};
  }

  .react-datepicker {
    width: 328px;
    z-index: 1000;

    .react-datepicker__time-list-item {
      background-color: ${COLOR.BG_INPUT};
    }
    .react-datepicker__time-list-item:hover {
      color: ${COLOR.BLACK};
      background-color: ${COLOR.BG_SECONDARY};

      opacity: 0.7;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day:hover {
      background-color: ${COLOR.BG_INPUT};
    }
    div {
      background-color: ${COLOR.BG_SECONDARY};
      color: ${COLOR.TEXT};
    }
    .react-datepicker__month {
      background-color: ${COLOR.BG_INPUT};
    }
  }

  .react-datepicker-popper {
    width: 320px;
    background-color: ${COLOR.BG_SECONDARY};
    color: ${COLOR.TEXT};
    z-index: 1000;
  }

  ${DateTimePickerFieldKitSD} {
    width: 100%;
  }

  ${SVGIconSD} {
    position: absolute;
    top: 12px;
    right: 15px;
    width: 14px;
    fill: #ffffff;
    pointer-events: none;
    ${({ disabled }) => disabled && 'opacity: 0.5'};
  }
`;
DateTimePickerKitSD.propTypes = {
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullWidth: PropTypes.bool,
};
DateTimePickerKitSD.defaultProps = {
  width: 200,
};
