import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { PopupKitSD, PopupKitWithoutPortalSD } from './styled';
import { debounce } from '../../../helpers/common';
import LevelUp from '../../presentation/LevelUp/LevelUp';

// for fix clickAnyWere event in IOS
// https://stackoverflow.com/questions/18524177/strange-click-event-bubble-on-iphone-safari-that-it-stop-before-bubbling-to-docu
window.root.onclick = () => {};

class PopupKit extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    anchorEl: PropTypes.shape({
      current: PropTypes.object,
    }),
    children: PropTypes.node.isRequired,
    minWidth: PropTypes.number,
    moveShadowDown: PropTypes.bool,
    maxHeight: PropTypes.number,
    withoutPadding: PropTypes.bool,
    /**
     * background color. #fff by default
     */
    bgColor: PropTypes.string,

    /* When this property is true, content which was passed will simply render in the place where it was. The other props (exclude isOpen) are ignored */
    disabledPortal: PropTypes.bool,
  };

  state = {
    didMount: false,
    gotHeight: false,
  };

  popupRef = React.createRef();

  constructor(props) {
    super(props);

    this.debouncedHandleResizeWindow = debounce(this.handleResizeWindow, 100);
  }

  componentDidMount() {
    if (!this.props.disabledPortal) {
      const popupWrap = (this.popupWrap = document.createElement('div'));
      popupWrap.style.width = '0';
      popupWrap.style.height = '0';
      document.body.appendChild(popupWrap);
    }

    if (this.props.isOpen) {
      this.open();
    }

    this.setState({ didMount: true });
  }

  componentDidUpdate(prevProps) {
    const propertyIsOpenWasChangedForClose =
      prevProps.isOpen && !this.props.isOpen;
    const propertyIsOpenWasChangedForOpen =
      !prevProps.isOpen && this.props.isOpen;

    if (propertyIsOpenWasChangedForClose) {
      this.close();
    }

    if (propertyIsOpenWasChangedForOpen) {
      this.open();
    }
  }

  componentWillUnmount() {
    this.close();
  }

  handleDocumentClickClose = e => {
    let target = e.target;
    const wrap = this.popupRef.current;

    while (target && !target.isEqualNode(wrap)) {
      target = target.parentElement;
    }

    if (!target) {
      this.close();
    }
  };

  handleResizeWindow = () => this.forceUpdate();

  open = () => {
    document.addEventListener('click', this.handleDocumentClickClose);

    if (!this.props.disabledPortal) {
      window.addEventListener('resize', this.debouncedHandleResizeWindow);
    }
  };

  close = () => {
    if (this.state.didMount) {
      this.props.onClose();
      document.removeEventListener('click', this.handleDocumentClickClose);

      if (!this.props.disabledPortal) {
        window.removeEventListener('resize', this.debouncedHandleResizeWindow);
      }
    }
  };

  toggle = () => {
    if (this.props.disabled) {
      return;
    }

    this.props.isOpen ? this.close() : this.open();
  };

  renderWithoutPortal = () => {
    const { children } = this.props;

    return (
      <LevelUp type={'popup'}>
        <PopupKitWithoutPortalSD ref={this.popupRef}>
          {children}
        </PopupKitWithoutPortalSD>
      </LevelUp>
    );
  };

  render() {
    const {
      isOpen,
      anchorEl,
      children,
      moveShadowDown,
      maxHeight,
      withoutPadding,
      bgColor,
      disabledPortal,
    } = this.props;

    if (!isOpen || !this.state.didMount) return null;

    if (disabledPortal) {
      return this.renderWithoutPortal();
    }

    const scrollTop = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    const scrollLeft = Math.max(
      window.pageXOffset,
      document.documentElement.scrollLeft,
      document.body.scrollLeft
    );
    const minWidth = this.props.minWidth;
    const boundRect = anchorEl.current.getBoundingClientRect();

    let top = scrollTop + boundRect.top + boundRect.height;
    let left = scrollLeft + boundRect.left;
    let width = boundRect.width < minWidth ? minWidth : boundRect.width;

    // for limit width
    if (width > document.documentElement.clientWidth) {
      width = document.documentElement.clientWidth - 20;
    }

    const MAX_HEIGHT_OF_POPUP = 300;

    // horizontal shifting
    const clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    if (left + width > clientWidth) {
      left = clientWidth - width;
    }

    // vertical shifting
    const documentHeight = document.documentElement.clientHeight + scrollTop;
    if (!this.popupRef.current) {
      window.setTimeout(() => this.forceUpdate(), 0);
    } else {
      const height = this.popupRef.current.clientHeight;
      if (height + top > documentHeight) {
        top = documentHeight - height;
      }
    }

    return createPortal(
      <LevelUp type={'popup'}>
        <PopupKitSD
          ref={this.popupRef}
          position={'absolute'}
          maxHeight={maxHeight || MAX_HEIGHT_OF_POPUP}
          width={width}
          top={top}
          left={left}
          moveShadowDown={moveShadowDown}
          withoutPadding={withoutPadding}
          bgColor={bgColor}
        >
          {children}
        </PopupKitSD>
      </LevelUp>,
      this.popupWrap
    );
  }
}

export default PopupKit;
