import breakpoints from 'helpers/breakpoints';
import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from '../constants/theme';

export const GlobalStyleSD = createGlobalStyle`
    ${styledNormalize}
   
    * {        
        &,
        &:before,
        &:after {
            box-sizing: border-box;
        }
        
        &:focus {
          outline: none;
        }
    }

    body {
        font: ${FONT_WEIGHT.NORMAL} 16px / 1.2 ${FONT_FAMILY.THIN};
        overflow-y: scroll;
        background-color: ${COLOR.BG_PRIMARY};
    }
    
    html, body {
        &.global-modal-is-open {
        	overflow: hidden;
          position: relative;
          width: 100%;
          height: 100%;
        }
    }

    html.is-locked, html.is-locked body {
  /* want to fix the height to the window height */
  ${breakpoints.down('xs')} {
    height: calc(var(--window-inner-height) - 1px);
  }

  /* want to block all overflowing content */
  overflow: hidden;

  /* want to exclude padding from the height */
  box-sizing: border-box;
}

/* very basic modal styles for brevity */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: none;
}

.model.is-open {
  display: block;
}

		#root-modal {
			position:relative;
		}
    
    .global-modal-container {
    	
    }

    #onetrust-banner-sdk .ot-sdk-container,
#onetrust-pc-sdk .ot-sdk-container,
#ot-sdk-cookie-policy .ot-sdk-container {
  background-color: ${COLOR.BG_PRIMARY};
  display: ${({ development }) => (development ? 'none' : 'block')}
}

#ot-sdk-btn-floating.ot-floating-button {
  display: none;
}

#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
    display: inline-block !important;

    color: #fff !important;
    padding: 0 !important;
    border: 0 !important;
    text-decoration: none;
  
    transition: color 0.3s !important;
}

#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings :hover {
    background-color: transparent !important;
}
`;
