import { STANDINGS_ACTIONS } from './constants';

const initialState = {
  list: [],
  page: 1,
  total: 1,
  isFetching: false,
};

export const standingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STANDINGS_ACTIONS.standings.request:
      return {
        ...state,
        isFetching: true,
      };
    case STANDINGS_ACTIONS.standings.success:
      return {
        ...state,
        isFetching: false,
        list: action.payload.leaderboard,
      };
    case STANDINGS_ACTIONS.standings.error:
      return {
        ...state,
        isFetching: false,
      };
    case STANDINGS_ACTIONS.changePage:
      return {
        ...state,
        page: action.payload,
      };
    case STANDINGS_ACTIONS.resetState:
      return initialState;
    default:
      return state;
  }
};
