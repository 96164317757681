import { TOURNAMENTS_ACTIONS } from './constants';
import { propValueOr } from '../../helpers/common';

const initialState = {
  list: [],
  featured: [],
  currentTournament: {},
  isFetchingCurrentTournament: false,
  isFetching: false,
  isFetchingFeatured: false,
  isAppending: false,
  maxFetchedPage: 1,
  total: 1,
};

export const tournamentsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOURNAMENTS_ACTIONS.tournaments.request:
      return {
        ...state,
        isFetching: true,
      };
    case TOURNAMENTS_ACTIONS.tournaments.success:
      return {
        ...state,
        isFetching: false,
        list: payload.list,
        total: payload.total,
        maxFetchedPage: 1,
      };
    case TOURNAMENTS_ACTIONS.tournaments.error:
      return {
        ...state,
        isFetching: false,
      };
    case TOURNAMENTS_ACTIONS.tournaments.appendRequest:
      return {
        ...state,
        isAppending: true,
      };
    case TOURNAMENTS_ACTIONS.tournaments.appendSuccess:
      return {
        ...state,
        list: [...propValueOr(state, 'list', []), ...payload.list],
        maxFetchedPage: state.maxFetchedPage + 1,
        isAppending: false,
      };
    case TOURNAMENTS_ACTIONS.tournaments.appendError:
      return {
        ...state,
        isAppending: false,
      };
    case TOURNAMENTS_ACTIONS.featured.request:
      return {
        ...state,
        isFetchingFeatured: true,
      };
    case TOURNAMENTS_ACTIONS.featured.success:
      return {
        ...state,
        isFetchingFeatured: false,
        featured: payload.list,
      };
    case TOURNAMENTS_ACTIONS.featured.error:
      return {
        ...state,
        isFetchingFeatured: false,
      };

    case TOURNAMENTS_ACTIONS.tournament.request:
      return {
        ...state,
        isFetchingCurrentTournament: true,
      };
    case TOURNAMENTS_ACTIONS.tournament.success:
      return {
        ...state,
        isFetchingCurrentTournament: false,
        currentTournament: action.payload,
      };
    case TOURNAMENTS_ACTIONS.tournament.error:
      return {
        ...state,
        isFetchingCurrentTournament: false,
      };

    case TOURNAMENTS_ACTIONS.resetState:
      return initialState;

    default:
      return state;
  }
};
