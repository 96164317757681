import React from 'react';
import { DAYS_OF_WEEK } from '../constants/common';
import moment from 'moment';
import {
  IconXbox,
  IconSwitch,
  IconXboxXS,
  IconPC,
  IconPS5,
  IconPS4,
} from 'icons';
import { COLOR } from 'constants/theme';

export const getClientFromPath = (path) => {
  if (path === '/') {
    return 'Rival Games';
  } else if (path && typeof path === 'string' && path.split('/').length > 1) {
    return path.split('/')[1];
  }
  return '';
};

export const getOrganizationFromPath = (path) => {
  if (path && typeof path === 'string' && path.split('/').length > 1) {
    console.log(path.split('/')[1]);
    return path.split('/')[1];
  }
  return '';
};

export const getAgesFromTournament = (tournament) => {
  const ages = propValueOr(tournament, 'restrictions.age', null);
  if (ages && ages.to && ages.from) {
    return `Ages ${ages.from} - ${ages.to}`;
  } else if (ages && ages.to) {
    return `Ages ${ages.to} and under`;
  } else if (ages && ages.from) {
    return `Ages ${ages.from} and up`;
  } else {
    return 'All Ages';
  }
};

export const prepareWeeksForRendering = (weeks) => {
  // console.error("prepareWeeksForRendering helper error. Weeks isn't array");
  if (typeof weeks !== 'object') {
    return weeks;
  }

  return Object.keys(weeks).map((week) => ({
    days: Object.keys(weeks[week].days).map((day) => ({
      title: DAYS_OF_WEEK[day] || day,
      value: day,
      times: weeks[week].days[day].map((time) => {
        const formattedTo12HoursTime = moment(time, 'HH:mm').format('h:mm A');

        return { title: formattedTo12HoursTime, value: time };
      }),
    })),
  }));
};

export const toDoubleDigits = (num) => {
  if (num === 0 || num === '0') {
    return '00';
  }

  return num < 10 ? '0' + num : num;
};

export const numberToSuffix = (num) => {
  const suffixes = [
    {
      suffix: 'K',
      threshold: 10000,
      divisor: 1000,
    },
    {
      suffix: 'M',
      threshold: 1000000,
      divisor: 1000000,
    },
    {
      suffix: 'B',
      threshold: 1000000000,
      divisor: 1000000000,
    },
  ];
  const suffix = suffixes.find((suffix) => suffix.threshold < Math.abs(num));
  let numStr = `${num}`;
  if (!!suffix) {
    const hasRemainder = num % suffix.divisor > 0;
    numStr = `${Math.floor(num / suffix.divisor)}${suffix.suffix}${
      hasRemainder ? '+' : ''
    }`;
  }
  return numStr;
};

export function parseStringTemplate(str, obj) {
  let parts = str.split(/\$\{(?!\d)[\wæøåÆØÅ]*\}/);
  let args = str.match(/[^{}]+(?=})/g) || [];
  let parameters = args.map(
    (argument) =>
      obj[argument] || (obj[argument] === undefined ? '' : obj[argument])
  );
  return String.raw({ raw: parts }, ...parameters);
}

export function arrToCollectionById(arr) {
  return arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}

export function debounce(fn, delay) {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };
}

export function snakeToText(str) {
  if (str) {
    return str.replace(/([-_][a-z])/g, (group) => group.replace('-', ' '));
  }
}

export function formatGamertag(str) {
  if (str) {
    return str.replace(/ /g, '_');
  }
}

export function parseGamertag(str) {
  if (str) {
    return str.replace(/^psn_/, '');
  }
}

export function parseTournamentId(str) {
  if (str && typeof str === 'string') {
    return str.replace(/-CL/, '');
  }
  return str;
}

export function getUsername(user, accountVar = null, alias = false) {
  return alias
    ? parseGamertag(
        propValueOr(
          user,
          'alias',
          propValueOr(
            user,
            accountVar,
            propValueOr(user, 'username', user?.id ? `User#${user?.id}` : '')
          )
        )
      )
    : !!propValueOr(user, accountVar)
    ? user[accountVar]
    : parseGamertag(
        propValueOr(user, 'username', user?.id ? `User#${user?.id}` : '')
      );
}

export function numberToWords(n) {
  var special = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
  ];
  var deca = [
    'twent',
    'thirt',
    'fort',
    'fift',
    'sixt',
    'sevent',
    'eight',
    'ninet',
  ];

  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
}

export function getBodyScrollWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
}

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const handleEventStopPropagation = (e) => {
  if (typeof e.stopPropagation === 'function') {
    e.stopPropagation();
  }
};

export const addUnit = (value) => {
  if (!value && value !== 0) return '0';

  if (typeof value === 'number') {
    return value === 0 ? '0' : `${value}px`;
  }

  const lastSymbol = value[value.length - 1];

  if (!isNaN(lastSymbol)) {
    return value === '0' ? '0' : `${value}px`;
  }

  return value;
};

export const parseJwt = (token) => {
  if (token) {
    if (token.split('.').length > 1) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } else {
      return token;
    }
  } else {
    return token;
  }
};

export const numberToThousandsString = (x) => {
  if (!x) return x;
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

/*
  Determine if object contains any of the properties provided
  returns true if object contains one of the provided properties
  retruns false if none of the properties are detected
  @param obj {object} - object being searched
  @param props {[string]} - array of property name strings 
  @return {boolean}
*/

export const containsOnePropOf = (obj, props) => {
  for (let prop of props) {
    if (prop in obj) return true;
  }

  return false;
};

/**
 * Get property value by path or defaultValue
 *
 * @param obj {object} - Object for finding
 * @param path {string} - path to a property
 * @param defaultValue {* = null} - default value. It will return if path will wrong. It has null by default value.
 * @return {*} Return value of property by specified path or passed default value or null if it do not pass
 */
export const propValueOr = (obj, path, defaultValue = null) => {
  if (!path) return defaultValue;

  return (
    path.split('.').reduce((acc, item) => {
      if (!acc) return acc;

      return acc[item];
    }, obj) || defaultValue
  );
};

/**
 * Safely attempt to get the property value of an object
 *
 * Note: This function works even if the end value is an empty string (''), 'false' boolean or '0' number value
 *
 * @param obj {object} - Object for finding
 * @param path {string} - (e.g. "a.b.c") path to a property in the object (separate property selectors with dots)
 * @param defaultValue {* = null} - default value. Returns this if path does not exist or resulting value is null/undefined. Default is null.
 * @return {*} Return value of property by specified path if not null/undefined, otherwise returns defaultValue
 */
export const safePropValueOr = (obj, path, defaultValue = null) => {
  if (!path) return defaultValue;
  let val = path.split('.').reduce((acc, item) => {
    if (!acc) return acc;

    return acc[item];
  }, obj);

  if (val === undefined || val === null) {
    val = defaultValue;
  }

  return val;
};

export const getBrowserInfo = () => {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }

  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    name: M[0],
    version: M[1],
  };
};

export class FixIOSBodyOverflow {
  browserInfo = getBrowserInfo();

  addOverflow() {
    if (
      this.browserInfo.name === 'Safari' &&
      this.browserInfo.version === '12'
    ) {
      document.querySelector('html').classList.add('global-modal-is-open');
    }
  }

  removeOverflow() {
    if (
      this.browserInfo.name === 'Safari' &&
      this.browserInfo.version === '12'
    ) {
      document.querySelector('html').classList.remove('global-modal-is-open');
    }
  }
}

/*
 * Prepared object for bb-tags
 */

export const getSearchPreparedOrganization = (organization) => ({
  title: `[link to="/organization/${organization.slug}"]${organization.name}[/link]`,
});

export const getSearchPreparedTournament = (tournament) => ({
  title: `[link to="/tournaments/${tournament.token}"]${tournament.name}[/link]`,
});

export const getSearchPreparedUser = (user) => ({
  image: propValueOr(user, 'imageInfo.thumbnail'),
  userProfileUrl: user.id,
  title: `[link to="/profiles/${user.id}"]${user.id}[/link]`,
  ...(process.env.REACT_APP_HIDE_SCHOOLS === 'false'
    ? {
        subtitle: `[link to="/organization/${propValueOr(
          user,
          'organization.slug'
        )}"]${propValueOr(user, 'organization.name')}[/link]`,
      }
    : {}),
});

// it is used for FreePlay dialog for convert the user info object to the player object
export const getPlayerObjFromUserInfo = (userInfo) => ({
  image: propValueOr(userInfo, 'imageInfo.thumbnail') || undefined,
  gamertag: propValueOr(userInfo, 'gamertag'),
  schoolName: (
    propValueOr(userInfo, 'organizations', []).find((o) => o.isPrimary) || {
      name: '',
    }
  ).name,
  win: propValueOr(userInfo, 'stats.win'),
  tie: propValueOr(userInfo, 'stats.tie'),
  loss: propValueOr(userInfo, 'stats.loss'),
  rank: propValueOr(userInfo, 'stats.rank'),
});

export const hasOnlyDigitsInString = (str) => /^\d*$/.test(str);

export const isObjectEmpty = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export function scrollToSmoothly(pos, time) {
  var currentPos = window.pageYOffset;
  var start = null;
  if (time == null) time = 500;
  let poss = +pos,
    timee = +time;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    var progress = currentTime - start;
    if (currentPos < poss) {
      window.scrollTo(0, ((poss - currentPos) * progress) / timee + currentPos);
    } else {
      window.scrollTo(0, currentPos - ((currentPos - poss) * progress) / timee);
    }
    if (progress < timee) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, poss);
    }
  });
}

export const getValues = (obj) => {
  if (!obj) return {};
  return Object.assign(
    ...Object.entries(obj).map(([key, values]) => ({
      [key]: values.description,
    }))
  );
};

export const getOrdinalSuffix = (n) => {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
};

export const getQueryStringFromObject = (queryOb) => {
  if (!queryOb || typeof queryOb !== 'object' || isObjectEmpty(queryOb)) {
    return '';
  }
  let queryStr = '',
    count = 0;
  for (const [key, value] of Object.entries(queryOb)) {
    queryStr += count === 0 ? '?' : '&';
    queryStr += key + '=' + value;
    count++;
  }
  return queryStr;
};

export const removeNullishValues = (arr) =>
  (arr || []).filter((item) => item != null);

export const sortAssetSeries = (a, b) => {
  const seriesPattern = new RegExp(/(?<name>^.*)-(?<indexNum>[\d]+$)/);
  let aIndex = !!a.order
    ? a.order
    : Number(seriesPattern.exec(a.name).groups.indexNum);
  let bIndex = !!b.order
    ? b.order
    : Number(seriesPattern.exec(b.name).groups.indexNum);
  if (isNaN(aIndex)) return isNaN(bIndex) ? 0 : 1;
  if (isNaN(bIndex)) return -1;
  return aIndex - bIndex;
};

export const getConsoleIcon = (consoleId) => {
  switch (consoleId) {
    case 1:
      return <IconXbox width={24} height={24} />;
    case 2:
      return <IconPS4 width={24} height={24} />;
    case 3:
      return <IconSwitch width={24} height={24} />;
    case 4:
      return <IconPC width={24} height={24} />;
    case 5:
      return <IconXboxXS width={24} height={24} />;
    case 6:
      return <IconPS5 width={24} height={24} />;
    default:
      return <></>;
  }
};

export const getConsoleColor = (consoleId) => {
  switch (consoleId) {
    case 1:
      return COLOR.XBOX_GREEN;
    case 2:
      return COLOR.PS_BLUE;
    case 3:
      return COLOR.NINTENDO_RED;
    case 4:
      return COLOR.PC_GREY;
    case 5:
      return COLOR.XBOX_GREEN;
    case 6:
      return COLOR.PS_BLUE;
    default:
      return COLOR.BLACK_2;
  }
};

export const getOrganizationColor = (organization) => {
  if (organization?.config?.primaryColor) {
    return organization.config.primaryColor;
  }
  return COLOR.RIVAL_RED;
};
