import { GAMES_ACTIONS } from './constants';

const initialState = {
  isFetching: false,
  list: [],
  isFetchingTemplates: false,
  templatesList: [],
};

export const gamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GAMES_ACTIONS.games.request:
      return {
        ...state,
        isFetching: true,
      };
    case GAMES_ACTIONS.games.success:
      return {
        ...state,
        list: action.payload,
        isFetching: false,
      };
    case GAMES_ACTIONS.games.error:
      return {
        ...state,
        isFetching: false,
      };
    case GAMES_ACTIONS.templates.request:
      return {
        ...state,
        isFetchingTemplates: true,
      };
    case GAMES_ACTIONS.templates.success:
      return {
        ...state,
        templatesList: action.payload,
        isFetchingTemplates: false,
      };
    case GAMES_ACTIONS.templates.error:
      return {
        ...state,
        isFetchingTemplates: false,
      };
    case GAMES_ACTIONS.resetState:
      return initialState;
    default:
      return state;
  }
};

// selectors

export const getStateGames = (state) => state.games;
