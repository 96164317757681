import React from 'react';
import PropTypes from 'prop-types';
import {SVGIconSD} from "./styled";

function IconSearch(props) {

	return (
		<SVGIconSD viewBox="0 0 21.2 22" style={{fill: "none", strokeWidth: "2px"}} width={props.width}>
			<g transform="translate(-260.000000, -6.000000)">
				<g transform="translate(266.000000, 10.000000)">
					<g>
						<path d="M8.6,11.4l5.6,5.6"/>
						<path d="M11.8,5c0,4.4-3.8,8-8.4,8S-5,9.4-5,5s3.8-8,8.4-8S11.8,0.6,11.8,5L11.8,5z"/>
					</g>
				</g>
			</g>
		</SVGIconSD>
	);
}

IconSearch.propTypes = {
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default IconSearch;
