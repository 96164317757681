import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
} from '../../../../constants/theme';
import { SVGIconSD } from '../../Icon/styled';
import { addUnit, getBrowserInfo } from '../../../../helpers/common';
import { MenuItemKitSD, MenuItemLinkKitSD, MenuKitSD } from '../../Menu/styled';
import { InputKitSD } from '../styled';

const getBackgroundColor = (appearance) => {
  if (!appearance || appearance === 'primary') {
    return COLOR.BG_INPUT;
  } else if (appearance === 'secondary') {
    return 'inherit';
  } else {
  }
};

const getBorder = (appearance, hasError) => {
  if (hasError) {
    return `1px solid ${COLOR.ERROR}`;
  }
  if (!!appearance && appearance === 'secondary') {
    return `1px solid ${COLOR.WHITE}`;
  } else {
    return `1px solid ${COLOR.BORDER}`;
  }
};

const getBorderRadius = (appearance) => {
  if (!!appearance && appearance === 'secondary') {
    return `20px`;
  } else {
    return `2px`;
  }
};

const FIELD_FLEX_STYLE = css`
  flex: 1 0 0;
`;
const FONT_ELLIPSIS_STYLES = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectKitSD = styled.div`
  position: relative;
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : addUnit(width))};
`;

SelectKitSD.propTypes = {
  fullWidth: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const SelectFieldKitSD = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 5px 15px;

  background-color: ${({ appearance }) => getBackgroundColor(appearance)};
  border: ${({ hasError, appearance }) => getBorder(appearance, hasError)};
  border-radius: ${({ appearance }) => getBorderRadius(appearance)};

  color: ${COLOR.FG_PRIMARY};
  font: ${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.FIELD}px / 1.1 ${FONT_FAMILY.THIN};
  letter-spacing: 0.4px;

  cursor: pointer;
  user-select: none;

  ${SVGIconSD} {
    max-width: 16px;
    height: auto;
  }

  ${_ifSelectIsOpenForField};
  ${_ifSelectDisabledForField};
`;
SelectFieldKitSD.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
};
function _ifSelectDisabledForField(props) {
  if (!props.disabled) return;

  return css`
    opacity: 0.5;
    cursor: not-allowed;
  `;
}
function _ifSelectIsOpenForField(props) {
  if (!props.isOpen || props.appearance === 'secondary') return;

  return css`
    border-bottom-color: ${COLOR.BORDER};
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.1),
      0 1px 15px 0 rgba(0, 0, 0, 0.1);
  `;
}

export const SelectFieldPlaceholderKitSD = styled.div`
  ${FIELD_FLEX_STYLE};
  color: ${COLOR.TEXT_5};
  font-weight: ${FONT_WEIGHT.NORMAL};
  ${FONT_ELLIPSIS_STYLES};
`;

export const SelectFieldValueKitSD = styled.div`
  ${FIELD_FLEX_STYLE};
  ${({ multiSelect }) => !multiSelect && FONT_ELLIPSIS_STYLES};
`;
SelectFieldValueKitSD.propTypes = { multiSelect: PropTypes.bool };

export const SelectFieldWrapMultipleValueSD = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-wrap: wrap;
  margin: -2px -2px -2px -12px;
`;

export const SelectFieldMultipleValueSD = styled.div`
  flex: 0 1 auto;

  display: flex;
  align-items: center;
  min-height: 28px;
  padding: 3px 5px;
  margin: 2px;

  background-color: ${COLOR.BG_INPUT};
  border-radius: 3px;
`;

export const SelectFieldMultipleValueLabelSD = styled.div`
  color: ${COLOR.TEXT_2};
  font: ${FONT_WEIGHT.BOLD} 14px / 1.2 ${FONT_FAMILY.THIN};
`;

export const SelectFieldMultipleValueRemoveSD = styled.div`
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  transform: rotate(45deg);

  &:hover {
    opacity: 0.7;
  }
`;

export const SelectDropDownOptionTextSD = styled(MenuItemLinkKitSD)`
  flex: 1 0 0;
  color: ${(props) => (props.isActive ? COLOR.FG_PRIMARY : COLOR.FG_PRIMARY)};
`;

export const SelectDropDownOptionIconSD = styled.div`
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  padding: 5px;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;

export const SelectIcon = styled.img`
  width: 25px;
  height: 25px;
`;

export const WrapMenuKit = styled.div`
  width: ${({ appearance }) => (appearance === 'secondary' ? '90%' : '100%')};
`;

export const SelectDropDownOptionSD = styled(MenuItemKitSD)`
  display: flex;
  align-items: center;
  background-color: ${COLOR.BG_INPUT};
  transition: all 0.2s;

  &:hover ${SelectDropDownOptionIconSD} {
    opacity: 1;
  }
  @media (hover: hover) {
    :hover {
      transition: all 0.2s;
      background-color: ${COLOR.BLACK};
    }
  }
  :active {
    // mobile
    transition: all 0s;
    background-color: ${COLOR.BLACK};
  }
`;

export const SelectWrapPopupKitSD = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid ${COLOR.BORDER};
  ${({ appearance }) =>
    appearance === 'secondary'
      ? `left: 16px;
    width: calc(100% - 32px);`
      : `left: 0;`}

  // fix bug on iphone Safari 12
  ${() => {
    const { name, version } = getBrowserInfo();

    if (name === 'Safari' && version === '12') {
      return css`
        &:after {
          content: '';
          display: block;
          visibility: hidden;
          height: 70px;
        }
      `;
    }
  }}
`;

export const SelectPopupKitSD = styled.div`
  max-height: 300px;
  padding: 14px 0;
  background-color: ${COLOR.BG_INPUT};
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 15px 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.RIVAL_RED};
    border-radius: 5.5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5.5px;
  }
`;

export const SelectSearchFieldWrapSD = styled.div`
  position: relative;

  & + ${MenuKitSD} {
    margin-top: 10px;
  }

  ${InputKitSD} {
    padding-right: 35px;
  }

  ${SVGIconSD} {
    position: absolute;
    width: 20px;
    height: auto;
    top: 10px;
    right: 10px;

    stroke: ${COLOR.BLACK_2};
  }
`;
