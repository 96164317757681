import { GAME_ACTIONS } from './constants';
import { propValueOr } from '../../helpers/common';

const initialState = {
  game: {},
  error: null,
};

export const gameReducer = (state = initialState, action) => {
  let users;
  switch (action.type) {
    case GAME_ACTIONS.fetch.request:
      return {
        ...state,
        error: null,
      };
    case GAME_ACTIONS.fetch.success:
      // Sort [home,away]
      users = propValueOr(action, 'payload.users', []).sort(
        (a, b) => b.isHome - a.isHome
      );
      // Update users scores and presence from snapshot (if exists)
      if (users.length > 1) {
        users[0].score = propValueOr(
          users[0],
          'score',
          propValueOr(action, 'payload.snapshot.homeScore', users[0].score)
        );
        users[0].status = propValueOr(
          action,
          'payload.snapshot.homeState',
          'Offline'
        );
        users[1].score = propValueOr(
          users[1],
          'score',
          propValueOr(action, 'payload.snapshot.awayScore', users[1].score)
        );
        users[1].status = propValueOr(
          action,
          'payload.snapshot.awayState',
          'Offline'
        );
      }
      return {
        ...state,
        game: {
          ...action.payload,
          users: users,
        },
      };
    case GAME_ACTIONS.fetch.error:
      return {
        ...state,
        game: {},
        error: action.payload,
      };
    case GAME_ACTIONS.pusher.update:
      // Sort [home,away]
      users = propValueOr(state, 'game.users', []).sort(
        (a, b) => b.isHome - a.isHome
      );
      // Update users scores and presence (if exists)
      if (users.length > 1) {
        users[0].score = propValueOr(
          action,
          'payload.home.score',
          users[0].score
        );
        users[0].status = propValueOr(action, 'payload.home.state', undefined);
        users[1].score = propValueOr(
          action,
          'payload.away.score',
          users[1].score
        );
        users[1].status = propValueOr(action, 'payload.away.state', undefined);
      }

      return {
        ...state,
        game: {
          ...state.game,
          users: users,
        },
      };
    case GAME_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};
