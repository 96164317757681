import React, { lazy, Suspense } from 'react';
const NotAuthorizedLayoutRouting = lazy(() =>
  import('./NotAuthorizedLayoutRouting').catch((e) => window.location.reload())
);
const AuthorizedLayoutRouting = lazy(() =>
  import('./AuthorizedLayoutRouting').catch((e) => window.location.reload())
);

function AppRouting({ user }) {
  return user.logged ? (
    <Suspense fallback={<></>}>
      <AuthorizedLayoutRouting user={user} />
    </Suspense>
  ) : (
    <Suspense fallback={<></>}>
      <NotAuthorizedLayoutRouting user={user} />
    </Suspense>
  );
}

export default AppRouting;
