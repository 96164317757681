import React from 'react';
import PropTypes from 'prop-types';
import { ButtonKitInnerFlexSD, ButtonKitSD, Icon } from './styled';
import IconPreloader from '../Icon/IconPreloader';

const ButtonKit = React.forwardRef(function ButtonKit(props, ref) {
  const { ...restProps } = props;

  return (
    <ButtonKitSD ref={ref} {...restProps}>
      <ButtonKitInnerFlexSD>
        {props.icon && <Icon src={props.icon} style={props.iconStyles} blinking={props.blinking}/>}
        {props.children}
        {props.preloader && <IconPreloader />}
      </ButtonKitInnerFlexSD>
    </ButtonKitSD>
  );
});

ButtonKit.propTypes = {
  /**
   * Response for style of button
   * 'primary' - button with background but without border (default)
   * 'secondary' - without background but with border
   */
  appearance: PropTypes.oneOf(['primary', 'secondary', 'inverted']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'inverted',
    'danger',
    'text-link',
    'green',
    'light-green',
    'red',
    'coke',
    'rival',
    'white',
    'blue',
  ]),
  fixedSize: PropTypes.bool,
  small: PropTypes.bool,
  uppercase: PropTypes.bool,
  fullWidth: PropTypes.bool,
  active: PropTypes.bool,
  preloader: PropTypes.bool,
  shape: PropTypes.oneOf(['rectangular', 'rounded']),

  // fixed width. fullWidth property overwrite this.
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
};

ButtonKit.defaultProps = {
  appearance: 'primary',
  color: 'primary',
  uppercase: true,
  shape: 'rectangular',
};

export default ButtonKit;
