import { LEAGUES_ACTIONS } from './constants';

const initialState = {
  leagues: [],
  page: 1,
  total: 1,
  fetchedAll: false,
  error: null,
  isFetching: false,
  isJoining: false,
  isLeaving: false,
};

export const leaguesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LEAGUES_ACTIONS.fetch.request:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case LEAGUES_ACTIONS.fetch.success:
      return {
        ...state,
        leagues: payload.list,
        page: payload.page,
        total: payload.total,
        isFetching: false,
        fetchedAll: false,
      };
    case LEAGUES_ACTIONS.fetch.error:
      return {
        ...state,
        leagues: [],
        error: payload,
        isFetching: false,
      };
    case LEAGUES_ACTIONS.append.request:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case LEAGUES_ACTIONS.append.success:
      return {
        ...state,
        leagues: [...state.leagues, ...payload.addition],
        page: payload.page,
        total: payload.total,
        isFetching: false,
      };
    case LEAGUES_ACTIONS.append.error:
      return {
        ...state,
        leagues: [],
        error: payload,
        isFetching: false,
      };
    case LEAGUES_ACTIONS.fetchedAll:
      return {
        ...state,
        fetchedAll: true,
        isFetching: false,
        error: null,
      };
    case LEAGUES_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};
