import CrossPlatformIcon from '../../static/icons/cross-platform.svg';
import CrossPlatformThumbnail from '../../static/images/cross-platform.svg';

export const CONSOLES_ACTIONS = {
  consoles: {
    request: 'CONSOLES/FETCH_REQUEST',
    success: 'CONSOLES/FETCH_SUCCESS',
    error: 'CONSOLES/FETCH_ERROR',
  },
  resetState: 'CONSOLES/RESET_STATE',
};

export const CrossPlatformConsole = {
  id: null,
  name: 'Cross-Platform',
  imageInfo: {
    icon: CrossPlatformIcon,
    thumbnail: CrossPlatformThumbnail,
  },
};
