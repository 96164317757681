import { CONFIG_ACTIONS, DEFAULT_CONFIG } from './constants';

const initialState = {
  assets: DEFAULT_CONFIG,
  affiliationId: null,
  config: {},
  id: null,
  subdomain: null,
  slug: null,
  isFetching: false,
  error: null,
};

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_ACTIONS.fetch.request:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case CONFIG_ACTIONS.fetch.success:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case CONFIG_ACTIONS.fetch.error:
      return {
        ...state,
        assets: DEFAULT_CONFIG,
        isFetching: false,
        error: action.payload,
      };
    case CONFIG_ACTIONS.fetch.empty:
      return {
        ...state,
        isFetching: false,
        error: 'Empty response',
      };
    case CONFIG_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};
