import { SEASON_ACTIONS } from './constants';

const initialState = {
  season: {},
  error: null,
  isFetching: false,
  isJoining: false,
  isLeaving: false,
};

export const seasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEASON_ACTIONS.fetch.request:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case SEASON_ACTIONS.fetch.success:
      return {
        ...state,
        season: action.payload,
        isFetching: false,
      };
    case SEASON_ACTIONS.fetch.error:
      return {
        ...state,
        season: {},
        error: action.payload,
        isFetching: false,
      };

    case SEASON_ACTIONS.join.request:
      return {
        ...state,
        isJoining: true,
      };
    case SEASON_ACTIONS.join.success:
      return {
        ...state,
        isJoining: false,
      };
    case SEASON_ACTIONS.join.error:
      return {
        ...state,
        isJoining: false,
      };

    case SEASON_ACTIONS.leave.request:
      return {
        ...state,
        isLeaving: true,
      };
    case SEASON_ACTIONS.leave.success:
      return {
        ...state,
        isLeaving: false,
      };
    case SEASON_ACTIONS.leave.error:
      return {
        ...state,
        isLeaving: false,
      };
    case SEASON_ACTIONS.playerCount.update:
      return {
        ...state,
        season: {
          ...state.season,
          playerCount: action.payload,
        },
      };
    case SEASON_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};
