import React from 'react';
import { connect } from 'react-redux';
import { api } from '../..';
import ButtonKit from '../../components/kit/Button/ButtonKit';
import DialogKit from '../../components/kit/Dialog/DialogKit';
import FormFieldKit from '../../components/kit/Fields/FormField/FormField';
import { propValueOr } from '../../helpers/common';
import { Container } from './styled';

class AccessCodeDialog extends React.Component {
  constructor(props) {
    super();

    this.state = {
      authorized: false,
      error: null,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { organizationId, onSuccess } = this.props;
    const { accessCode } = this.state;

    this.setState({ loading: true });

    api.organization
      .access(organizationId, { accessCode })
      .then(resp => {
        this.setState({ authorized: true });
        onSuccess();
      })
      .catch(err => {
        this.setState({
          error: propValueOr(err, 'response.data.message', err.message),
        });
      })
      .finally(() => this.setState({ loading: false, accessCode: '' }));
  };

  renderBody = () => {
    return (
      <Container onSubmit={this.handleSubmit}>
        <FormFieldKit
          label={'Access Code'}
          value={this.state.accessCode}
          onChange={val => this.setState({ accessCode: val, error: null })}
          inputProps={{ maxLength: 50 }}
          fullWidth
          type={'password'}
          errorText={this.state.error}
          disabled={this.state.loading}
          placeholder={'Enter access code here...'}
        />
        <ButtonKit
          shape={'rounded'}
          type={'submit'}
          fullWidth
          preloader={this.state.loading}
          disabled={!this.state.accessCode}
        >
          Submit
        </ButtonKit>
      </Container>
    );
  };

  render() {
    const { authorized } = this.state;
    return (
      <DialogKit
        isOpen={!authorized}
        displayButtonClose={false}
        title={'Access Code Required'}
        renderBody={this.renderBody}
      />
    );
  }
}

const mapStateToProps = state => ({
  organizationId: state.configState.id,
});

export default connect(mapStateToProps)(AccessCodeDialog);
